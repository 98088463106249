/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './signin.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
// firebase 
import { useSelector } from 'react-redux';
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Modal from "../../../components/MDNotification"
import Image from "assets/images/success.png";
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted }) {
  const [rememberMe, setRememberMe] = useState('');

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const user = useSelector(state => state.user);
  const [texte, setTexte] = useState("Certains cours sont 100% femmes ou 100% hommes. En renseignant votre genre, ne seront affichés sur l’application que les cours mixtes et de votre genre.")
  const [color, setColor] = useState('#000000');
  const [error, setError] = useState(false);
  const [gender, setGender] = useState('')
  const [openNotif, setOpenNotif] = useState(false)
  const [message, setMessage] = useState("")
  const [className, setClassName] = useState("modalContainer")
  const [disableButton, setDisableButton] = useState(false)
  const colors = {"green": "#26D98C", "light":"#EFF4F2", "purple":"#5D1EF7"}
  const handleGenderSelect = (event) =>{
     event.preventDefault()
     var element = document.getElementById(event.target.id);
     var computedStyles = window.getComputedStyle(element);
     var borderColor = computedStyles.borderColor;
     var borderColorRgb = borderColor.match(/\d+/g).map(function(value) {
       return parseInt(value);
     });
 
     if (borderColorRgb[0] === 38 && borderColorRgb[1] === 217 && borderColorRgb[2] === 140) {
       document.getElementById(event.target.id).style.borderColor = colors.light

       setGender("")
       setDisableButton(false)
     }else {
      document.getElementById(event.target.id).style.borderColor = colors.purple;
      setDisableButton(true)
      if (event.target.id == "femme") {
        setGender("female")
        document.getElementById("homme").style.borderColor = colors.light
      }else{
        setGender("male")
        document.getElementById("femme").style.borderColor = colors.light
      }
     }
  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {
   
      if (res.data.length != 0) {
        if (res.data?.gym?.completedAt) {
            if(res.data?.rules?.completedAt){
              if(res.data?.refunded?.completedAt){
                setSubmitted(true)
                setIndex(13)
              }else{
                setIndex(12)
              }
            }else{
              setIndex(11)
            }
          
          
        }else{
          setIndex(9)
        }
           }
      
    })
  }
  const updateUser = async()=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${user.email}`)
    .then(async(res) => {
      const g = res.data
      await axios
    .patch(`${process.env.REACT_APP_API_DEV}/athletes/${user._id}`,
    {
      email: user.email,
      gender: gender,
    },).then(async(res)=>{

      setError(false)
     await navigatePages()
    })
    })
  }

  const updateFreeTrialGender= async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          gender: {
            res: gender,
            completedAt: new Date()
          }
        })
      }
    })
  }
const onsubmit = async() =>{

  await updateUser()
  await updateFreeTrialGender()
}
  useEffect(() => {   

  }, []);


  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={54.6}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center">
          <Typography color={color} variant='h4' marginTop={2} justifyContent='center' fontWeight='bold'>
          Veuillez choisir votre genre
            </Typography>
          </MDBox>
            
          <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                  marginTop={2}
                >
                <MDBox textAlign="center"
                >
                  <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>

                <MDBox marginTop={2} component='form' role='form'>
                
                 <MDBox marginTop={3} mb={2}>
        
              <div className='gender-buttons-contaner' >
                    <button id="femme" className="gender-buttons" onClick={(e)=>handleGenderSelect(e)}>Femme</button>
                    <button id="homme" className="gender-buttons" onClick={(e)=>handleGenderSelect(e)}>Homme</button>
              </div>
                   
                  </MDBox>

                  <MDBox marginTop={3} textAlign='center'>
                    {!disableButton && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }
                   {disableButton && 
                     <MDButton
                     onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                   color='purple'
                   >
                     Continuer
                   </MDButton>
                    }
                  </MDBox>
               
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      {openNotif && <Modal setOpenModal={setOpenNotif} img={Image} msg={message} className={className}/>}
    </PageLayout>
  );
}

export default Basic;
