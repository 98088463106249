/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {
  const [rememberMe, setRememberMe] = useState('');

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const maxTrialTickets = useSelector(state => state.maxtrialtickets);

  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState("Dernière étape! Nos règles d'utilisation:");
  const [noneDisp, setNoneDisp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [classIsBefore, setClassIsBefore] = useState()
  const [bookedAfter,setBookedAfter] = useState()
  const [bookedBefore,setBookedBefore] = useState()
  const user = useSelector(state => state.user);

  const [error, setError] = useState(false);
  useEffect(async() => {   
    getSettings()
  }, []);

    const getSettings = async()=>{
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/settings`)
      .then(async(res) => {
        setClassIsBefore(res.data[0].urgentBookings.classIsBefore)
        setBookedBefore(res.data[0].urgentBookings.bookedBefore)
        setBookedAfter(res.data[0].urgentBookings.bookedAfter)
      })
    }
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  
    const handleSubmit = () => {
      if (isChecked) {
        // Handle submission of the form

      } else {
        // Display error message

      }
    };
    const updateFreeTrialRules = async() =>{
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
      .then(async(res) => {
 
        if (res.data.length != 0) {
  
          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
          {
            rules:{
              res: isChecked,
              completedAt: new Date()
            }
          }).then(()=>{
            if(res.data?.refunded?.completedAt){
              setSubmitted(true)
              setIndex(13)
            }else{
              setIndex(12)
            }
          })
          
        }
      })
    }
  const onsubmitt = async () => {
    await updateFreeTrialRules()
   // setSubmitted(true)
  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={78}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center" marginTop={1.5}>
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={3}
              >
                <MDBox marginTop={2} component='form' role='form'>
                  
                <div className="scrollable-container">
      <p className="termsText">Les 2 séances doivent obligatoirement être réservées chez 2 partenaires différents. NB: chaque studio OM yoga est un partenaire indépendant.
        </p>
        <p className="termsText">La musculation, la natation et les cours collectifs nécessitent tous une réservation à l’avance.</p>       
          <br />
      <p className="termsText">- Vous pouvez réserver jusqu’à 1 heure avant le cours.</p>
      <p className="termsText">- Chaque cours a un délai d'annulation.</p>
      <p className="termsText">- Vous avez droit à un maximum de {maxTrialTickets} réservations sur la période d'essai </p>
      <p className="termsText">- Si vous ne vous présentez pas au cours réservé, la réservation est tout de même comptabilisée</p>
      <p className="termsText">- La période d'essai commence dès la date de début et non à partir de la première réservation </p>
      <p className="termsText">- Afin de garantir votre réservation, il est impossible de réserver des cours du lendemain matin avant {classIsBefore}h tard le soir après {bookedAfter}h ou tôt le matin avant {bookedBefore}h. </p>
      <br />
      <p className="termsText">Nous sommes ravis de vous accueillir dans l'univers Yofitt: votre sport en toute liberté!</p>
          </div>

                    <MDBox marginTop={2}>
                    
                    <div className="container-check"  style={{ display: 'flex'}}>
                    <FormControlLabel 
                    style={{ display: 'inline-block' }}
                    control={<Checkbox onChange={e => {setIsChecked(e.target.checked)}}/>} 
                    />
                    <p className='terms' style={{ display: 'inline-block' }}>
                            <span class="accept">J'accèpte </span>
                            <span class="link"><a href="https://yofitt.com/conditions-generales-dutilisation/" target="_blank">les conditions générales d'utilisations</a></span>
                            

                            </p>
                    </div>
                   
                  
                  </MDBox>
                  <MDBox marginTop={3}>
                  {!isChecked && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }
                   {isChecked && 
                     <MDButton
                     className={`ButtonRounded ${noneDisp ? 'noneDisp' : ''} `}
                       onClick={onsubmitt}
                       fullWidth
                       color='purple'
                     >
                       Continuer
                     </MDButton>
                    }
                    
                  </MDBox>

            
                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
