/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import IconButton from '@mui/material/IconButton'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import Image from "assets/images/success.png";
// firebase 
import { useSelector } from 'react-redux';
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {
  const user = useSelector(state => state.user);
  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState('Dites nous quelle formule vous convient le mieux pour après votre période d’essai');
  const [text1, setTexte1] = useState("Ceci n’est en aucun cas un engagement de votre part");
  const [disableButton, setDisableButton] = useState(false)
  const [subType, setSubType] = useState("")
  const [display, setDisplay] = useState(false)
  const colors = {"green": "#26D98C", "light":"#EFF4F2", "purple":"#5D1EF7"}
  const [error, setError] = useState(false);
  let offersData = []
  useEffect(() => {  
    offersData.push({"title":"Pass semaine", "bold": "499", "text":"Sans engagement"})
    offersData.push({"title":"Pass mensuel", "bold": "999", "text":"Sans engagement"})
    offersData.push({"title":"Abonnement 4 mois", "bold": "899", "text":"Avec engagement"})
    offersData.push({"title":"Abonnement 12 mois", "bold": "749", "text":"Avec engagement"} )
    setDisplay(true)


  }, []);
  
  const handleSelect = (event) =>{
    event.preventDefault()
    var element = document.getElementById(event.target.id);
    var computedStyles = window.getComputedStyle(element);
    var borderColor = computedStyles.borderColor;
    var borderColorRgb = borderColor.match(/\d+/g).map(function(value) {
      return parseInt(value);
    });

    if (borderColorRgb[0] === 38 && borderColorRgb[1] === 217 && borderColorRgb[2] === 140) {
      document.getElementById(event.target.id).style.borderColor = colors.light
      setSubType("")
      setDisableButton(false)
    }else {
     document.getElementById(event.target.id).style.borderColor = colors.purple;
     setDisableButton(true)
     if (event.target.id == "1") {
       setSubType("1mois")
       document.getElementById("4").style.borderColor = colors.light
       document.getElementById("12").style.borderColor = colors.light
       document.getElementById("decouvrir").style.borderColor = colors.light
     }else if(event.target.id == "4"){
        setSubType("4mois")
        document.getElementById("1").style.borderColor = colors.light
        document.getElementById("12").style.borderColor = colors.light
        document.getElementById("decouvrir").style.borderColor = colors.light
     }else if(event.target.id == "12"){
        setSubType("12mois")
        document.getElementById("1").style.borderColor = colors.light
        document.getElementById("4").style.borderColor = colors.light
        document.getElementById("decouvrir").style.borderColor = colors.light
     }else{
        setSubType("je veux juste découvrir")
        document.getElementById("1").style.borderColor = colors.light
        document.getElementById("4").style.borderColor = colors.light
        document.getElementById("12").style.borderColor = colors.light
     }
    }
  }
  const updateFreeTrialOffer= async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          offer: {
            res: subType,
            completedAt: new Date()
          }
        }).then(()=>{
          if(res.data?.rules?.completedAt){
            if(res.data?.refunded?.completedAt){
              setSubmitted(true)
              setIndex(13)
            }else{
              setIndex(12)
            }
          }else{
            setIndex(11)
          }
        })
      }
    })
  }
  const handleButtonClick = (event, element) =>{
 //   setSubType(element)

     setDisableButton(true)
    setSubType(element)
    if (element == "semaine") {
    document.getElementById("semaine").style.borderColor = colors.purple
    document.getElementById("semaine").style.background = colors.purple
    document.getElementById("card-semaine").style.borderColor = colors.purple
    document.getElementById("plan-semaine").style.borderColor = colors.purple

    document.getElementById("1mois").style.borderColor = "#e2ebf6"
    document.getElementById("1mois").style.background = "white"
    document.getElementById("card-1mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-1mois").style.borderColor = "#e2ebf6"
    document.getElementById("4mois").style.borderColor = "#e2ebf6"
    document.getElementById("4mois").style.background = "white"
    document.getElementById("card-4mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-4mois").style.borderColor = "#e2ebf6"
    document.getElementById("12mois").style.borderColor = "#e2ebf6"
    document.getElementById("12mois").style.background = "white"
    document.getElementById("card-12mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-12mois").style.borderColor = "#e2ebf6"
    }else if(element == "1mois"){

    document.getElementById("1mois").style.borderColor = colors.purple
    document.getElementById("1mois").style.background = colors.purple
    document.getElementById("card-1mois").style.borderColor = colors.purple
    document.getElementById("plan-1mois").style.borderColor = colors.purple

    document.getElementById("semaine").style.borderColor ="#e2ebf6"
    document.getElementById("semaine").style.background = "white"
    document.getElementById("card-semaine").style.borderColor = "#e2ebf6"
    document.getElementById("plan-semaine").style.borderColor = "#e2ebf6"
    document.getElementById("4mois").style.borderColor = "#e2ebf6"
    document.getElementById("4mois").style.background = "white"
    document.getElementById("card-4mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-4mois").style.borderColor = "#e2ebf6"
    document.getElementById("12mois").style.borderColor = "#e2ebf6"
    document.getElementById("12mois").style.background = "white"
    document.getElementById("card-12mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-12mois").style.borderColor = "#e2ebf6"
    }else if(element == "4mois"){
    document.getElementById("4mois").style.borderColor = colors.purple
    document.getElementById("4mois").style.background = colors.purple
    document.getElementById("card-4mois").style.borderColor = colors.purple
    document.getElementById("plan-4mois").style.borderColor = colors.purple

    document.getElementById("1mois").style.borderColor = "#e2ebf6"
    document.getElementById("1mois").style.background = "white"
    document.getElementById("card-1mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-1mois").style.borderColor = "#e2ebf6"
    document.getElementById("semaine").style.borderColor ="#e2ebf6"
    document.getElementById("semaine").style.background = "white"
    document.getElementById("card-semaine").style.borderColor = "#e2ebf6"
    document.getElementById("plan-semaine").style.borderColor = "#e2ebf6"
    document.getElementById("12mois").style.borderColor = "#e2ebf6"
    document.getElementById("12mois").style.background = "white"
    document.getElementById("card-12mois").style.borderColor = "#e2ebf6"
    document.getElementById("plan-12mois").style.borderColor = "#e2ebf6"
    }else if(element == "12mois"){
      document.getElementById("12mois").style.borderColor = colors.purple
      document.getElementById("12mois").style.background = colors.purple
      document.getElementById("card-12mois").style.borderColor = colors.purple
      document.getElementById("plan-12mois").style.borderColor = colors.purple

      document.getElementById("4mois").style.borderColor = "#e2ebf6"
      document.getElementById("4mois").style.background = "white"
      document.getElementById("card-4mois").style.borderColor = "#e2ebf6"
      document.getElementById("plan-4mois").style.borderColor = "#e2ebf6"
      document.getElementById("1mois").style.borderColor = "#e2ebf6"
      document.getElementById("1mois").style.background = "white"
      document.getElementById("card-1mois").style.borderColor = "#e2ebf6"
      document.getElementById("plan-1mois").style.borderColor = "#e2ebf6"
      document.getElementById("semaine").style.borderColor ="#e2ebf6"
      document.getElementById("semaine").style.background = "white"
      document.getElementById("card-semaine").style.borderColor = "#e2ebf6"
      document.getElementById("plan-semaine").style.borderColor = "#e2ebf6"
     
    }
    
  }
  const onsubmit = async() =>{
    await updateFreeTrialOffer()

  }
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={70.2}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center" marginTop={2} >

          <Typography color={color} variant='h5' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
          <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                  marginTop={0.5}
                >
                <MDBox textAlign="center"
                >
                  <p className={`textbottom ${error ? 'error' : ''} `} >{text1}</p>
                </MDBox>
                <div className="scrollable-container-offer" style={{marginTop:"20px"}}>
                <div class="grid">
                <label class="card" id="card-semaine" >
            <input name="plan" class="radio" id="semaine" type="radio" checked onClick={(e)=>{handleButtonClick(e,"semaine")}} />
            
            <span class="plan-details" id="plan-semaine">
              <span class="plan-type">Pass semaine</span>
              <span class="plan-cost">499 dhs <span class="plan-text"> sans engagement</span></span>
              

            </span>
          </label>
              
          <label class="card" id="card-1mois" >
            <input name="plan" id="1mois" class="radio" type="radio" checked onClick={(e)=>{handleButtonClick(e,"1mois")}}/>
            
            <span class="plan-details" id="plan-1mois">
              <span class="plan-type">Pass mensuel</span>
              <span class="plan-cost">799 dhs <span class="plan-text"> sans engagement</span></span>
              

            </span>
          </label>

{/**
 *    <label class="card" id="card-4mois" style={{marginTop:-10}}>
            <input name="plan" id="4mois" class="radio" type="radio" checked onClick={(e)=>{handleButtonClick(e,"4mois")}}/>
            
            <span class="plan-details" id="plan-4mois">
              <span class="plan-type-engagement">Abonnement 4 mois</span>
              <span class="plan-cost">899 dhs<span class="slash">/</span><abbr class="plan-cycle" title="month">mois</abbr><span class="plan-text"> avec engagement</span></span>
              

            </span>
          </label>

          <label class="card" id="card-12mois" style={{marginTop:-10}}>
            <input name="plan" class="radio" id="12mois" type="radio" checked onClick={(e)=>{handleButtonClick(e,"12mois")}}/>
            
            <span class="plan-details" id="plan-12mois">
              <span class="plan-type-engagement">Abonnement 12 mois</span>
              <span class="plan-cost">749 dhs<span class="slash">/</span><abbr class="plan-cycle" title="month">mois</abbr> <span class="plan-text"> avec engagement</span></span>
             

            </span>
          </label>
 */}
       


</div>
                </div>

                <MDBox marginTop={1} component='form' role='form'>
                {/** 
                 * <MDBox marginTop={3} mb={2}>
                    <button id="1" className="gender-buttons" onClick={(e)=>handleSelect(e)}>1 mois</button>
                    <button id="4" className="gender-buttons" onClick={(e)=>handleSelect(e)}>4 mois</button>
                    <button id="12" className="gender-buttons" onClick={(e)=>handleSelect(e)}>12 mois</button>
                    <button id="decouvrir" className="gender-buttons" onClick={(e)=>handleSelect(e)}>Je veux juste découvrir</button>
                  </MDBox>
                */}


                  <MDBox marginTop={4} textAlign='center'>
                    {!disableButton && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }
                   {disableButton && 
                     <MDButton
                     onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                   color='purple'
                   >
                     Continuer
                   </MDButton>
                    }
                  </MDBox>
               
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
