import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import SwipeableViews from 'react-swipeable-views';
import VerifyEmail from "./verify-email"
import VerifyCode from "./verify-code"
import PhoneNumber from "./phone-number"
import VerifyPhoneNumber from "./verify-phone-code"
import FavouriteActivities from "./favourite-activities"
import Gender from "./gender"
import AddSelfie from "./add-selfie"
import AddCin from "./add-cin"
import LastScrenn from "./last-screen"
import GymSubscription from "./gym-subscription"
import AreYouInterested from "./are-you-interested"
import StartAt from "./start-date"
import TermsAndConditions from "./terms-conditions"
import SalesPage from "./sales-page"
import Cities from "./cities"
import Checkout from "./checkout-page"
import Paiment from "./Paiment"
import Refunded from "./refunded"
import BirthDate from "./birth-date"
import FavouriteGyms from "./favourite-partenrs"
import WarningScreen from "./warning-screen"
import { UserProvider } from '../../contexte/userProvider';

import "./style.css"
function Swipeableviews() {
    const [index, setIndex] = useState(0)
    const [emailTrasnfer, setEmailTransfer] = useState("")
    const [passwordTransfer, setPasswordTransfer] = useState("")
    const [displayNameTransfer, setDisplayNameTransfert] = useState("")
    const [phoneNumberTransfer, setPhoneNumberTransfer] = useState("")
    const [countDown, setCountDown] = useState(false)
    const [countDownEmail, setCountDownEmail] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {   
    //  console.log("submitted : ",submitted)
    }, [submitted]);
    return (
      <UserProvider>
      <div>
        {/**
         *  {submitted &&
        <Confetti 
        numberOfPieces={800}
        recycle={false}
        gravity={0.1}
        colors={['#f44336','#e91e63','#9c27b0','#673ab7','#3f51b5','#2196f3','#03a9f4','#00bcd4','#009688','#4CAF50','#8BC34A','#CDDC39','#FFEB3B','#FFC107','#FF9800','#FF5722','#795548']}
      
     />
      }
         */}
     
        <SwipeableViews index={index} onChangeIndex={setIndex} disabled >
          {/**      
           * 
          */}  

            <div>
            <VerifyEmail setIndex={setIndex} setEmailTransfer={setEmailTransfer} setPasswordTransfer={setPasswordTransfer} setDisplayNameTransfert={setDisplayNameTransfert} setCountDownEmail={setCountDownEmail} setSubmitted={setSubmitted} />
            </div>  
            <div>
            <PhoneNumber setIndex={setIndex} setPhoneNumberTransfer={setPhoneNumberTransfer} setCountDown={setCountDown}/>
            </div>   
             <div>
            <VerifyPhoneNumber setIndex={setIndex} phoneNumberTransfer={phoneNumberTransfer} emailTrasnfer={emailTrasnfer} countDown={countDown} setCountDown={setCountDown} setSubmitted={setSubmitted}/>
            </div> 
            <div>
            <Cities setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
             <div>
            <StartAt setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div> 
              <div>
            <FavouriteActivities setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
            <div>
            <FavouriteGyms setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
            <div>
            <BirthDate setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
            <div>
            <Gender setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
             <div>
            <GymSubscription setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
            <div>
            <AreYouInterested setIndex={setIndex} setSubmitted={setSubmitted}/>
            </div>
            <div>
            <TermsAndConditions setIndex={setIndex} setSubmitted={setSubmitted}  />
            </div> 
            <div >
            <Refunded setIndex={setIndex} setSubmitted={setSubmitted} />
            </div>
            <div>
            <Checkout setIndex={setIndex} submitted={submitted} setSubmitted={setSubmitted} />
            </div>
            {/**
             * <div>
            <LastScrenn setIndex={setIndex} submitted={submitted} setSubmitted={setSubmitted} />
            </div>
             */}
            
            <div>
            <SalesPage setIndex={setIndex}  />
            </div>
            <div>
            <WarningScreen setIndex={setIndex} submitted={submitted}/>
            </div> 
        </SwipeableViews>
        
      </div>
      </UserProvider>
    );
}

export default Swipeableviews;