import './activities.css'

import React from 'react'

import { useEffect, useState } from "react";

const CloudActivities = ({cat, filter, remaining}) => {
const  itemsPerLine =  Math.ceil(cat.length / 3)

  return (
    <>
    <div className='navbarr'>
      <div className='navbarr-links'>
        {cat.map((c, index) => {
          if (index % itemsPerLine === 0) {
            return (
              <ul key={index} style={{listStyle: "none"}}>
                {cat.slice(index, index + itemsPerLine).map(c => (
                  <li key={c._id}>
                    <div className='links' onClick={(event) => filter(event, c._id)}>
                      <button className='button' id={c._id} key={c._id}>{c.name}</button>
                    </div>
                  </li>
                ))}
              </ul>
            )
          }
          return null
        })}
      
      </div>
    </div>
    {/**   <div className='navbarr2'>
            <div className='navbarr-links'>
      {remaining.map((c, index) => {

    return (
      <ul key={index} style={{listStyle: "none"}}>
        {remaining.map(c => (
          <li key={c._id}>
            <div className='links' onClick={(event) => filter(event, c._id)}>
              <button className='button' id={c._id} key={c._id}>{c.name}</button>
            </div>
      </li>
    ))}
  </ul>
)

})}
      </div>
    </div>*/}
  
    </>
  )
}
export default CloudActivities