/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import Icon from '@mui/material/Icon'
import { Typography } from '@mui/material'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import 'react-toastify/dist/ReactToastify.css';
import './addSelfie.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PhoneImg from "../../../assets/images/Mobile-cuate.png"
import { toast } from 'react-toastify'
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Progress from "components/MDProgress"
// axios
import axios from "axios";
import Webcam from "react-webcam";
import React from "react";
import { useSelector } from 'react-redux';
import Compressor from 'compressorjs';
import IconButton from '@mui/material/IconButton'
// firebase 
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { app } from  '../../../firebaseConfig';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { CListGroup } from "@coreui/react-pro";
import { async } from "@firebase/util";
import { useDispatch } from 'react-redux';
import Spinner from "../components/Spinner";
import { Stream } from "@mui/icons-material";
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'
const storage = getStorage(app);
// Create the file metadata
/** @type {any} */
const metadata = {
  contentType: 'image/jpeg'
};
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
function Basic({setIndex, setSubmitted}) {
  const fileSelfieRef = React.createRef();
  const user = useSelector(state => state.user);
  const size = useWindowSize();
  const [disableButton, setDisableButton] = useState(false)
  const [enableCamera, setEnableCamera] = useState(false)
  const [appearingText, setAppearingText] = useState(true)
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [texte, setTexte] = useState('Pour des questions de sécurité, nous avons le devoir de nous assurer de l’unicité de votre identité.')
  const [image, setImage] = useState(null);
  const webcamRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const photoRef = React.useRef(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [stream, setStream] = useState(null);
  useEffect(() => {
  
  }, []);
  const handleImgDelete = (im) => {
    if (isUploading == false) {
      setImage(null)
      setDisableButton(false)
      setAppearingText(true)
    }

  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
          if (res.data?.gym?.completedAt) {
            if (res.data?.offer?.completedAt) {
              if(res.data?.rules?.completedAt){
                setSubmitted(true)
                setIndex(11)
              }else{
                setIndex(10)
              }
              
            }else{
              setIndex(9)
            }
            
          }else{
            setIndex(8)
          }
        }
                
              

      
    })
  }
  const updateFreeTrialSelfie= async(selfie) =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          selfie: {
            res: selfie,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const capture = (event) => {
    event.preventDefault()
    setEnableCamera(false)
    const base64Image = webcamRef.current.getScreenshot();
    setImage(base64Image);
  //  console.log(base64Image)
    setAppearingText(false)

    if (base64Image) {
      setDisableButton(true)
    }
  };
  function handlePhotoSelect(event) {
    const file = event.target.files[0];
    setImage(file)

    setAppearingText(false)
    setDisableButton(true)
    
   // console.log(reader.readAsDataURL(file))
  }
  const openCamera = (event) =>{
    event.preventDefault()
    setEnableCamera(true)
    setAppearingText(false)

  }
  const handleButtonClick = (event) => {
    event.preventDefault()
    fileSelfieRef.current.click();
  };
  const sendSelfie = async()=>{
    const file = image;

    const storageRef = ref(storage, user._id + '/selfiePictures/' + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata );
    setIsUploading(true)
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
   
      switch (snapshot.state) {
        case 'paused':

          break;
        case 'running':
   
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    async() => {
      // Upload completed successfully, now we can get the download URL
     await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await updateFreeTrialSelfie(downloadURL)
          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/athletes/${user._id}`,
          {
            email: user.email,
            selfie: downloadURL,
          },).then(async(res)=>{
   
            setError(false)
            setIsUploading(false)
            await navigatePages()
    })  
      });
    }
  );

  }
  const convertBase64ToFile = (base64Image) => {
    // Split the Base64 string into its metadata and data components
    const imageData = base64Image.split(";base64,").pop();
  
    // Convert the data component to a Uint8Array
    const byteCharacters = atob(imageData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create a new Blob object from the Uint8Array and return it
    const blob = new Blob([byteArray], { type: "image/png" });
    return new File([blob], "image.png", { type: "image/png" });
  };

  const onsubmit = async () => {

          sendSelfie()
  
  }
  return (
    <PageLayout>
          {
          /**
           *           <MDBox sx={{position: 'relative', textAlign:'center'}}>
              <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          style={{
                             // position: "absolute",
                              textAlign: "center",
                              justifyContent:'center',
                              zindex: 8,
                              right:0,
                              height: size.height,
                              width: 'auto',
                              objectFit: "fill",
                            }}
                        />        
                    <button className="capture-button" onClick={(event)=>{capture(event)}}>Ca</button> 
            </MDBox>
           */
                          }
   
                    
       {!enableCamera && 
             <MDBox
             px={1}
             width='100%'
             bgColor='white'
             height='99vh'
             sx={{
               backgroundSize: '120% !important',
               backgroundPosition: '45% -250px !important',
               backgroundRepeat: 'no-repeat',
             }}
             mx='auto'
           >
             <Navbar />
             
             <Grid
               container
               spacing={1}
               justifyContent='center'
               alignItems='center'
             >
               <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
               <Progress  color='purple' value={55}/>
                    <Typography color={"#000000"} variant='h4' justifyContent='center' textAlign={'center'} fontWeight='bold' marginTop={appearingText? 10 : 2 } >
                    Ajoutez un selfie
                 </Typography>
                  
                 <Box>
                   <MDBox
                     //coloredShadow='dark'
                     //pt={4}
                     //pb={3}
                     //px={3}
                       marginTop={2}
                     >
                         <MDBox textAlign="center">
                         <p className={`textbottomm ${error ? 'error' : ''} `}>{texte}</p>
                       </MDBox>
                     <MDBox marginTop={2} component='form' role='form'>

                
                    <MDBox mb={2} marginTop={1}  >
                     
                     
                    {image && (
                        <MDBox >
                           <DefaultProjectCard
                             
                             image={URL.createObjectURL(image)}
                                label=''
                                title=''
                                description=''
                                action={{ route: () => handleImgDelete(image) }}
                                authors={[]}
                                width={image}
                              /> 
                          </MDBox>
                          
                       )} 
                     {
                         appearingText && 
                         <MDBox>
                         <button id="browse" className="browse-buttons" onClick={(event)=>{handleButtonClick(event)}}>Prenez un selfie</button>
                            <input
                                type="file"
                                ref={fileSelfieRef}
                                style={{ display: 'none' }}
                                accept="image/*" 
                                onChange={handlePhotoSelect}
                            />
                       </MDBox>
                       /**<button onClick={(event)=>{openCamera(event)}} id="selfie" className="browse-buttons">Prenez un selfie</button> */  
                       }
                       </MDBox> 
                      <MDBox marginTop={appearingText? 3 : 0 } textAlign='center'>
                      {!disableButton && 
                       <MDButton
                     //  onClick={(event)=>{capture(event)}}
                       fullWidth
                     //  sx={{width:190}}
                       color='light'
                     >
                       Continuer
                     </MDButton>
                      }
                      {isUploading &&
                        <Spinner />
                       }
                     {disableButton && !isUploading &&
                  <MDButton

                    onClick={onsubmit}
                    fullWidth
                    color='purple'
                    
                    >
                       Continuer
                    </MDButton>
                      }
                    </MDBox>
                     </MDBox>
                   </MDBox>
                 </Box>
               </Grid>
             </Grid>
           </MDBox>
       }


    </PageLayout>
  );
}

export default Basic;
