export const SET_USER = 'SET_USER';
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_MAXTRIALTICKETS  = 'SET_MAXTRIALTICKETS'
export const SET_CITIES  = 'SET_CITIES'
export const SET_NBROFDAYS = 'SET_NBROFDAYS'
export const SET_MONTANT = 'SET_MONTANT'
export const SET_SETTINGS_Img = 'SET_SETTINGS_Img'

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user
  };
}

export function setSettings(settings) {
  return {
    type: SET_SETTINGS,
    payload: settings
  };
}

export function setMaxtrialtickets(maxtrialtickets) {
  return {
    type: SET_MAXTRIALTICKETS,
    payload: maxtrialtickets
  };
}

export function setCities(cities) {
  return {
    type: SET_CITIES,
    payload: cities
  };
}

export function setNbrOfDays(nbrofdays) {
  return {
    type: SET_NBROFDAYS,
    payload: nbrofdays
  };
}

export function setMontant(montant) {
  return {
    type: SET_MONTANT,
    payload: montant
  };
}

export function setSettingsImages(settingsImg) {
  return {
    type: SET_SETTINGS_Img,
    payload: settingsImg
  };
}