/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import logo from 'assets/images/brand.svg'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard/bannerDisplay'
import banner from 'assets/images/PROMOGold.png'
import arrow from 'assets/images/arrow2.png'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex}) {
  const [rememberMe, setRememberMe] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const maxTrialTickets = useSelector(state => state.maxtrialtickets);

  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState("Profitez de la promo ci-dessous!");
  const [texte1, setTexte1] = useState("Les utilisateurs ayant été invité à une séance ne peuvent plus bénéficier de période d'essai.")
  const [texte2, setTexte2] = useState("")
  const [noneDisp, setNoneDisp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const settingImgs = useSelector(state => state.settingsImg);
  const [error, setError] = useState(false);
  const [arrowVisible, setArrowVisible] = useState(true);
  useEffect(async() => {   

  }, []);
  const handlePromoClick = () => {

    window.open('https://yofittapp.page.link/pass', '_blank');
  };
  const handleSubscriptionClick = () => {
 
    window.open('http://www.yofitt.com/abonnement', '_blank');
  };
  const onsubmitt = async () => {
   

  }
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
          <nav className='appnavbar'>
      <div className='appnavbar-logo'>
        <img src={logo} alt='applogo' />
      </div>

    </nav>
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
          marginTop={5}
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <MDBox textAlign="center" marginTop={3}>
          <p className={`text`}>{texte1}</p>

          </MDBox>
          <MDBox textAlign="center" marginTop={2}>
          <p className={`text`}>{texte2}</p>
          </MDBox>
          <MDBox textAlign="center" marginTop={3}>
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold'>
           
            </Typography>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={2}
              >
                  {arrowVisible && (
        <div className="arrow-container">

          <img className="arrow" src={arrow} />
        </div>
      )}
              <MDButton
                sx={{marginTop:4,
                  transform: isHovered ? 'scale(1.05)' : 'scale(1)',}}
                className={`ButtonRounded`}
                variant="outlined"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                fullWidth
                color={isHovered ? "purple"  : 'black' }
                onClick={handleSubscriptionClick}
              >
             <ShoppingCartIcon />
            Voir nos offres
          </MDButton>


        
          {/**
           *       
           *  <MDBox marginTop={4}>
              
                           <DefaultProjectCard
                             
                                image={settingImgs[0]}
                                label=''
                                title=''
                                description=''
                                action={{ route: () =>  console.log("hello") }}
                                authors={[]}
                                handlePromoClick={handlePromoClick}
                              /> 
                          </MDBox>
                          <MDBox marginTop={2}>
                           <DefaultProjectCard
                             
                                image={settingImgs[1]}
                                label=''
                                title=''
                                description=''
                                action={{ route: () =>  console.log("hello") }}
                                authors={[]}
                                handlePromoClick={handleSubscriptionClick}
                              /> 
                          </MDBox>
           *  */  }
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;