import { SET_USER, SET_SETTINGS, SET_MAXTRIALTICKETS, SET_CITIES, SET_NBROFDAYS, SET_MONTANT, SET_SETTINGS_Img } from './actions';

const initialState = {
  user: null,
  settings: null,
  maxtrialtickets: null,
  cities: null,
  nbrofdays: null,
  montant: null,
  settingsImg: ["https://storage.googleapis.com/yofitt-2e712.appspot.com/setting/PASS.png", "https://storage.googleapis.com/yofitt-2e712.appspot.com/setting/Promo%2025.png"]
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
      case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
      case SET_MAXTRIALTICKETS:
      return {
        ...state,
        maxtrialtickets: action.payload
      };
      case SET_CITIES:
      return {
        ...state,
        cities: action.payload
      };
      case SET_NBROFDAYS:
      return {
        ...state,
        nbrofdays: action.payload
      };
      case SET_MONTANT:
      return {
        ...state,
        montant: action.payload
      };
      case SET_SETTINGS_Img:
      return {
        ...state,
        settingsImg: action.payload
      };
    default:
      return state;
  }
}