/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import React, { useRef} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PhoneImg from "../../../assets/images/Mobile-cuate.png"
import { toast } from 'react-toastify'
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import VerificationCodeInput from "react-verification-code-input";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Progress from "components/MDProgress"
// axios
import axios from "axios";
// firebase 
import { useSelector } from 'react-redux';
import { app } from  '../../../firebaseConfig';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { CListGroup } from "@coreui/react-pro";
import Modal from "../../../components/MDNotification"
import OtpImg from "../../../assets/images/otp.png"
import Image from "assets/images/success.png";
import Spinner from "../components/Spinner";
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, phoneNumberTransfer, emailTrasnfer, countDown, setCountDown, setSubmitted}) {
  const [errors, setErrors] = useState('');
  const user = useSelector(state => state.user);
  const [error, setError] = useState(false);
  const [openNotif, setOpenNotif] = useState(false)
  const [message, setMessage] = useState("")
  const inputRefs = useRef([...Array(4)].map(() => React.createRef()));
  const [values, setValues] = useState(Array(4).fill(""));
  const [className, setClassName] = useState("modalContainer")
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [texte, setTexte] = useState('Un code vous a été envoyé par sms au '+ phoneNumberTransfer + '. Veuillez entrer le code puis cliquez sur "continuer"')
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {  
    if (error == false) {
      setTexte('Un code vous a été envoyé par sms au '+ phoneNumberTransfer + '. Veuillez entrer le code puis cliquez sur "continuer"')
    }
    
    if(seconds > 0 && countDown){
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }else if(seconds <= 0){
      setCountDown(false)
      setSeconds(59)
    }

  }, [seconds, countDown]);
 
  const focusNext = (index) => {
    if (index === 3 || values[index + 1]) {
      return;
    }
    inputRefs.current[index + 1].current.focus();
  };
  const sendVerificationCode = async() => {
    await 
    axios.post(`${process.env.REACT_APP_API_DEV}/sms/sendverifycode`,
    {
      to: phoneNumberTransfer,
      channel: "sms",
    },).then((res)=>{
      setSeconds(59)
      setCountDown(true)
    })
  }
  const handleChange = (index, value) => {
    setError(false)
    setTexte('Un code vous a été envoyé par sms au '+phoneNumberTransfer +'.  Veuillez entrer le code puis cliquez sur "continuer"')
    const newValues = [...values];
    value=value.slice(0,1)
    newValues[index] = value;
    setValues(newValues);
    focusNext(index);
  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {
   
      if (res.data.length != 0) {
        if (res.data?.city?.res) {
        if(res.data?.startAt?.res){
          if (res.data.activities.res.length != 0) {
            if (res.data?.birthday?.res) {
              const selectedDate = new Date(res.data?.birthday?.res); // Convert moment object to JavaScript Date object
              const today = new Date();
              const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
              const differenceInMonths = today.getMonth() - selectedDate.getMonth();
              const differenceInDays = today.getDate() - selectedDate.getDate();
              if (differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0)) {
                if (res.data?.gender?.res) {
                  if (res.data?.gym?.completedAt) {
            
                      if(res.data?.rules?.completedAt){
                        if(res.data?.refunded?.completedAt){
                          setSubmitted(true)
                          setIndex(13)
                        }else{
                          setIndex(12)
                        }
                      }else{
                        setIndex(11)
                      }
                    
                    
                  }else{
                    setIndex(9)
                  }

              }else{
                setIndex(8)
              }
              }else{
                setIndex(7)
              }
            }else{
              setIndex(7)
            }
           }else{
            setIndex(5)
           }
        }else{
          setIndex(4)
        }
      }else{
        setIndex(3)
      }
      }
    })
  }
  const updateUser = async()=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${user.email}`)
    .then(async(res) => {
      const g = res.data
  
      await axios
    .patch(`${process.env.REACT_APP_API_DEV}/athletes/${g._id}`,
    {
      email: g.email,
      phoneNumber: phoneNumberTransfer,
    },).then((res)=>{
      
      
      setError(false)
        setIsTimedOut(false);

        setIsTimedOut(false);
        setMessage("Votre numéro a été vérifié avec succès!")
        setClassName("modalContainer")
        setOpenNotif(true)
        const timeoutIdd = setTimeout(async() => {
        setIsTimedOut(true);
        setOpenNotif(false)
        setIsUploading(false)
        await navigatePages()
      }, 1000);
    })
    })
  }
  const verifyCode = async()=>{
    const code = values[0]+values[1]+values[2]+values[3]
    await 
    axios.post(`${process.env.REACT_APP_API_DEV}/sms/checkverifycode`,
    {
      to: phoneNumberTransfer,
      code: code,
    },).catch(function (error) {
      if (error.response) {
    
        setError(true)
        setIsUploading(false)
        setTexte('Le code que vous avez entré est expiré. Veuillez entrer le dernier code que vous avez reçu ou cliquez sur re-envoyer le code.')
       //sendVerificationCode()
      }
    })
    .then(async(res)=>{

      if (res.data.valid) {
       
        await updateUser()
        await updateFreeTrialVerifyPhone()
        
       
      }else if(res.data.valid == false){
     
        setError(true)
        setIsUploading(false)
        setTexte('Code incorrect. Veuillez vérifier et entrer le code que vous avez reçu par SMS')
      }
    }
    )
  }
  const updateFreeTrialVerifyPhone = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          verifyPhoneNumber:{
            res: true,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const onsubmit = async () => {
    const code = values[0]+values[1]+values[2]+values[3]
    if (code.length == 4) {
      setIsUploading(true)
      verifyCode()
    }else{
      setError(true)
      setTexte("Veuillez vérifier le code que vous avez reçu")
    }
  }
  const returnHome = ()=>{
    setError(false)
    setIndex(1)
  }
  const resendCode = ()=>{
   // notifyFailure()

  if (countDown == false) {
    sendVerificationCode()
    setError(false)
    setIsTimedOut(false);
    setMessage("Code de vérification re-envoyé avec succès!")
    setClassName("modalContainer")
    setOpenNotif(true)
    const timeoutIdd = setTimeout(() => {
    setIsTimedOut(true);
    setOpenNotif(false)
  }, 1000);
    
  }

  }
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='99vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'

        >
          <Grid item marginTop={-1} xs={11} sm={9} md={5} lg={4} xl={3} >
          <Progress color='purple' value={15.6}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center" marginTop={-4}>
              <div className="iconBack">
                <img className="mailIcon" src={OtpImg} alt="image description" />
              </div>
            </MDBox>
            <MDBox textAlign="center" >
            <Typography color={"#000000"} variant='h4' textAlign={'center'} justifyContent='center' fontWeight='bold'>
            Vérifiez votre téléphone
            </Typography>
            </MDBox>
            

            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={1}
                >
                <MDBox textAlign="center"
                 marginTop={2}
                >
                  <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>

                <MDBox marginTop={1} component='form' role='form'>
                
                 <MDBox textAlign="center" mb={2}>
                          {values.map((value, index) => (
                  <input
                    key={index}
                    ref={inputRefs.current[index]}
                    type="number"
                    max='1' min='1'
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "160px",
                      fontSize:"16px",
                      border: "1px solid #ccc",
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                    maxLength={1}
                    value={value}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && !value) {
                        if (index === 0) return;
                
                        inputRefs.current[index - 1].current.focus();
                      }
                    }}
                  />
                ))}

                  </MDBox>
                  {isUploading &&
                        <Spinner />
                       }
                       {
                        !isUploading &&
                        <MDBox marginTop={1} textAlign='center'>
                        <MDButton
                          onClick={onsubmit}
                          fullWidth
                        //  sx={{width:190}}
                        color='purple'
                        >
                          Continuer
                        </MDButton>
                      </MDBox>
                       }
               
                  <MDBox mt={3} mb={1} textAlign='center'>
                    <MDTypography variant='button' textTransform="none" color='error'>
                      {errors}
                    </MDTypography>
                  </MDBox>
                  {!isUploading &&  <MDBox marginTop={-1}>
                    <MDButton
                      variant='text'
                      color='white'
                      textAlign="left"
                      onClick={returnHome}
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        fontWeight='bold'
                        textTransform="none"
                      >
                        <p className='url' >
                        Changer mon numéro
                        </p>
                      </MDTypography>
                    </MDButton>
                  </MDBox>}
                 
                  <MDBox marginTop={-1}>
                    <MDButton
                      variant='text'
                      color='white'
                      onClick={resendCode}
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        fontWeight='bold'
                        textTransform="none"
                      >
                        {countDown && !isUploading && <p className='countDown'>Vous pourrez re-envoyer le code dans 00:{seconds}</p>}
                        {!countDown && !isUploading && <p className='url'>Re-envoyer le code </p>}
                        
                      </MDTypography>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      {openNotif && <Modal setOpenModal={setOpenNotif} img={Image} msg={message} className={className}/>}
    </PageLayout>
  );
}

export default Basic;