

import { GiCrossedBones } from 'react-icons/gi'
import { GiHamburgerMenu } from 'react-icons/gi'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React from 'react'
import logo from 'assets/images/Yofitt Brand Assets-03.svg'
import './Navbar.css'
const Navbar = () => {
  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <img src={logo} alt='app__logo' />
      </div>

    </nav>
  )
}

export default Navbar
