/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PhoneImg from "../../../assets/images/Mobile-cuate.png"
import { toast } from 'react-toastify'
// Authentication layout components
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard/cinDisplay'
import PageLayout from 'examples/LayoutContainers/PageLayout'

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Progress from "components/MDProgress"
// axios
import axios from "axios";
import { useSelector } from 'react-redux';
import React from "react";
// firebase 
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { app } from  '../../../firebaseConfig';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { CListGroup } from "@coreui/react-pro";
import { async } from "@firebase/util";
import { useDispatch } from 'react-redux';
import Spinner from "../components/Spinner";
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'
const storage = getStorage(app);
// Create the file metadata
/** @type {any} */
const metadata = {
  contentType: 'image/jpeg'
};
function Basic({setIndex}) {

  const fileInputRef = React.createRef();
  const user = useSelector(state => state.user);
  const [disableButton, setDisableButton] = useState(false)
  const [appearingText, setAppearingText] = useState(true)
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [texte, setTexte] = useState('Pour des questions de sécurité, nous avons le devoir de vérifier votre identité.')
  const [cin, setCin] = useState(null)  
  useEffect(() => {   
   if (cin != null) {
    setDisableButton(true)
    setAppearingText(false)
   }
  }, [cin]);
  
  const updateFreeTrialCin= async(cin) =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          cin: {
            res: cin,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        if (res.data?.gym?.completedAt) {
      
            setIndex(11)
          
        }else{
          setIndex(9)
        }
      }
    })
  }
  const sendCin = async()=>{
    const file = cin
      const storageRef = ref(storage, user._id + '/cinPictures/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata );
  setIsUploading(true)
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;


      switch (snapshot.state) {
        case 'paused':
   
          break;
        case 'running':
 
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    }, 
    async() => {
      // Upload completed successfully, now we can get the download URL
     await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
      await updateFreeTrialCin(downloadURL)
          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/athletes/${user._id}`,
          {
            email: user.email,
            cin: downloadURL,
          },).then(async(res)=>{

            if (res.data.cin) {
                setIsUploading(false)
                await navigatePages()
            } 
            setError(false)
            
    })  
      });
    }
  );

  }
  const handleButtonClick = (event) => {
    event.preventDefault()
    fileInputRef.current.click();
  };
  const onsubmit = async () => {
    sendCin()
  }
  const handleImgDelete = (im) => {
    if (isUploading == false) {
      setCin(null)
      setDisableButton(false)
      setAppearingText(true)
    }

  }
  function handlePhotoSelect(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    setCin(file)

   // console.log(reader.readAsDataURL(file))
  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='99vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'

        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Progress  color='purple' value={66}/>
            {/**
             *             <MDBox textAlign="center">
              <div className="iconBack">
                <img className="mailIcon" src={PhoneImg} alt="image description" />
              </div>
            </MDBox>
             */}
               <Typography color={"#000000"} variant='h4' justifyContent='center' textAlign={'center'} fontWeight='bold' marginTop={appearingText? 10 : 2 }>
               Ajoutez votre CIN
            </Typography>

            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                  marginTop={2}
                >
                <MDBox textAlign="center">
                  <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>

                <MDBox marginTop={2} component='form' role='form'>
                
                 <MDBox mb={2} marginTop={1} >
                 {cin && (
                        <MDBox >
                           <DefaultProjectCard
                             
                                image={URL.createObjectURL(cin)}
                                label=''
                                title=''
                                description=''
                                action={{ route: () => handleImgDelete(image) }}
                                authors={[]}
                              /> 
                          </MDBox>
                          
                       )} 
                       
                       { appearingText && 
                       <MDBox>
                         <button id="browse" className="browse-buttons" onClick={(event)=>{handleButtonClick(event)}}>Parcourir</button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*" 
                                onChange={handlePhotoSelect}
                            />
                       </MDBox>

                       }
                
                  </MDBox>
                  <MDBox marginTop={appearingText? 3 : 0 } textAlign='center'>
                    {!disableButton && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Télécharger
                   </MDButton>
                    }
                     {isUploading &&
                        <Spinner />
                       }
                   {disableButton && !isUploading &&
                  
                <MDButton
                  onClick={onsubmit}
                    fullWidth
                    color='green'
                  >
                    Télécharger
                  </MDButton>
                    }
                  </MDBox>


                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;