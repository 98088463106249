import React from "react";
import "./notification.css";
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Image from "assets/images/success.png";

import StarIcon from '@mui/icons-material/Star';
import axios from 'axios'
export default function Modal({ setOpenModal, img, msg, className}) {
    const handleClose = ()=>{
      setOpenModal(false);
    }
    React.useEffect(() => {

      },)
    const convertToClick = (e) => {
      const evt = new MouseEvent('click', { bubbles: true })
      evt.stopPropagation = () => {}
      e.target.dispatchEvent(evt)
    }
  return (
    <div className="modalBackground" >

      <div class={className}>

        <div className="group">
        <div className="avatar">
        <Avatar alt="Remy Sharp"  src={Image} sx={{ width: 50, height: 50 }}/>
         </div>
         <div className="body">

        <div className="reviews">
            <p className="textVerifyy">{msg}</p>

        </div>
         </div>
            </div>   
      </div>
    </div>
  );
}
