/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import IconButton from '@mui/material/IconButton'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Image from "assets/images/success.png";
import { DatePicker, Space } from 'antd';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import moment from 'moment';
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {
  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState('Choisissez la date de début de votre période d’essai');
  const [disableButton, setDisableButton] = useState(false)
  const [ifOui, setIfOui] = useState(false)
  const [showButton, setShowButtons] = useState(true)
  const freeTrialPeriod = useSelector(state => state.settings);
  const [text1, setTexte1] = useState(`Votre période d’essai expirera ${freeTrialPeriod} jours après la date de début.`);
  const [gymName, setGymName] = useState("")
  const [date, setDate] = useState(null)
  const user = useSelector(state => state.user);
  const [error, setError] = useState(false);
  const { RangePicker } = DatePicker;
  useEffect(async() => {   
    setTexte1(`Votre période d’essai expirera ${freeTrialPeriod} jours après la date de début. Veuillez choisir une date de début parmi les 30 prochains jours.`)
    
  }, [freeTrialPeriod]);

  const updateFreeTrialStartAt = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
            startAt:{
            res: date,
            completedAt: new Date()
          }
        }).then(async()=> await navigatePages())
      }
    })
  }

  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        if (res.data.activities.res.length != 0) {
          if (res.data?.birthday?.res) {
            const selectedDate = new Date(res.data?.birthday?.res); // Convert moment object to JavaScript Date object
            const today = new Date();
            const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
            const differenceInMonths = today.getMonth() - selectedDate.getMonth();
            const differenceInDays = today.getDate() - selectedDate.getDate();
            if (differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0)) {
              if (res.data?.gender?.res) {
                if (res.data?.gym?.completedAt) {
                    if(res.data?.rules?.completedAt){
                      if(res.data?.refunded?.completedAt){
                        setSubmitted(true)
                        setIndex(13)
                      }else{
                        setIndex(12)
                      }
                    }else{
                      setIndex(11)
                    }
                  
                  
                }else{
                  setIndex(9)
                }

            }else{
              setIndex(8)
            }
            }else{
              setIndex(7)
            }
          }else{
            setIndex(7)
          }
           }else{
            setIndex(5)
           }
      }
    })
  }
  function onChange(date, dateString) {

    if (date) {
      // If date is not null, user has selected a date
      setDisableButton(true) 
      setDate(date.format())
    } else {
      // If date is null, user has cleared the date
      setDisableButton(false)
    }
  }
  const onOk = (value) => {
    // console.log('onOk: ', value);
   };
   function disabledDate(current) {
    // Disable all dates before today
    return (
      current &&
      (current < moment().startOf('day') ||
        current > moment().add(30, 'days').endOf('day'))
    );
  }
const onsubmit = async()=>{
  await updateFreeTrialStartAt()

}
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={31.2}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center" marginTop={0} >

          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold' >
           {welcomeMsg}
            </Typography>
          </MDBox>
          <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={1}
                >
                <MDBox textAlign="center"
                 marginTop={2}
                >
                  <p className={`textbottom ${error ? 'error' : ''} `}>{text1}</p>
                </MDBox>

                <MDBox marginTop={2} component='form' role='form'>
                <MDBox mb={2}>
                   <MDBox mb={2}>
                     <DatePicker 
                     placeholder="Date de début" 
                     className={`datepicker`}
                     style={{ width: '100%', height: 45, borderRadius: 160}}
                     onChange={onChange} 
                     onOk={onOk} 
                     format='MMM Do, YYYY'
                     disabledDate={disabledDate}
                     />
                  </MDBox>
                  </MDBox>
                 <MDBox marginTop={3} textAlign='center'>
                   {!disableButton && 
                    <MDButton
                   // onClick={onsubmit}
                    fullWidth
                  //  sx={{width:190}}
                    color='light'
                  >
                    Continuer
                  </MDButton>
                   }
                  {disableButton && 
                    <MDButton
                    onClick={onsubmit}
                    fullWidth
                  //  sx={{width:190}}
                  color='purple'
                  >
                    Continuer
                  </MDButton>
                   }
                 </MDBox>
              
               </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
