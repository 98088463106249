import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

// @mui material components
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton'
// react-router-dom components
import { Link } from 'react-router-dom'
import MDAvatar from 'components/MDAvatar'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import boxShadow from 'assets/theme/functions/boxShadow'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',

    fontSize: 14,
  },
}))

function DefaultProjectCard({
  image,
  label,
  title,
  description,
  action,
  authors,
  handlePromoClick

}) {

   

  return (

  <div id="container-image" style={{ position: 'relative' }}>
  <img 
  id="image"
  src={`${image}`} 
  alt="image description" 
  onClick={handlePromoClick}
  style={{
        maxWidth: '100%',
        minWidth: '100%',
        width: '100%',
        height:"auto",
        display: 'block',
        margin: '0 auto',
        borderRadius: 20,
        cursor: 'pointer' 
      }} />
  {/**    <CardMedia
    ref={imageRef}
      src={`${image}`}
      component='img'
      title={title}
      style={{
        maxWidth: '100%',
        width: 'auto',
        height:"280px",
        display: 'block',
        margin: '0 auto',
      }}
    /> */}

  </div>

  )
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
}

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // action: PropTypes.shape({
  //  type: PropTypes.oneOf(["external", "internal"]),
  //  route: PropTypes.string.isRequired,
  //  color: PropTypes.oneOf([
  //    "primary",
  //    "secondary",
  //    "info",
  //    "success",
  //   "warning",
  //    "error",
  //    "light",
  //    "dark",
  //   "white",
  //  ]).isRequired,
  //  label: PropTypes.string.isRequired,
  // }).isRequired,
  action: PropTypes.shape({
    route: PropTypes.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
}

export default DefaultProjectCard