import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

// @mui material components
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton'
// react-router-dom components
import { Link } from 'react-router-dom'
import MDAvatar from 'components/MDAvatar'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import boxShadow from 'assets/theme/functions/boxShadow'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',

    fontSize: 14,
  },
}))

function DefaultProjectCard({
  image,
  label,
  title,
  description,
  action,
  authors,
  width
}) {
  const [iconPosition, setIconPosition] = useState({ top: -10, right: 0 });
  const imageRef = React.createRef(null);

  useEffect(() => {

    const handleResize = () => {
    const reader = new FileReader();
    reader.readAsDataURL(width);
    reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      const divElement = document.getElementById('container-image');
      const ImageElement = document.getElementById('image');
      const imageWidth =ImageElement.offsetWidth
      const width = divElement.offsetWidth;
      const pourcentage = (((width -imageWidth )/width)*50)


     // setIconPosition({ top: -10, left: `${(imageWidth - 40/2)/imageWidth*100}%` });
     setIconPosition({ top: -10, right: `${pourcentage}%`});
            };
        };
      
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (

  <div id="container-image" style={{ position: 'relative' }}>
  <img 
  id="image"
  src={`${image}`} 
  alt="image description" 
  style={{
        maxWidth: '100%',
        width: 'auto',
        height:"280px",
        display: 'block',
        margin: '0 auto',
        borderRadius: 20
      }} />
  {/**    <CardMedia
    ref={imageRef}
      src={`${image}`}
      component='img'
      title={title}
      style={{
        maxWidth: '100%',
        width: 'auto',
        height:"280px",
        display: 'block',
        margin: '0 auto',
      }}
    /> */}

    <IconButton
      style={{
        position: 'absolute',
        backgroundColor: '#F44335',
        border: '4px white solid',
        width: 40,
        height: 40,
        ...iconPosition,
      }}
      size='medium'
      onClick={action.route}
      aria-label='delete'
      color='white'
    >
      <Tooltip title='Delete image' placement='bottom'>
        <ClearOutlinedIcon />
      </Tooltip>
    </IconButton>
  </div>

  )
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
}

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // action: PropTypes.shape({
  //  type: PropTypes.oneOf(["external", "internal"]),
  //  route: PropTypes.string.isRequired,
  //  color: PropTypes.oneOf([
  //    "primary",
  //    "secondary",
  //    "info",
  //    "success",
  //   "warning",
  //    "error",
  //    "light",
  //    "dark",
  //   "white",
  //  ]).isRequired,
  //  label: PropTypes.string.isRequired,
  // }).isRequired,
  action: PropTypes.shape({
    route: PropTypes.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
}

export default DefaultProjectCard

