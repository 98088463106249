/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import 'react-toastify/dist/ReactToastify.css';
import './signin.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MailImg from "../../../assets/images/New message-pana.png"
import { toast } from 'react-toastify'
// Authentication layout components

import PageLayout from 'examples/LayoutContainers/PageLayout'
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import Modal from "../../../components/MDNotification"
// Images
import Image from "assets/images/success.png";
import { useDispatch } from 'react-redux';
// axios
import axios from "axios";
// firebase 
import Progress from "components/MDProgress"
import { makeStyles } from '@material-ui/core/styles';
import { async } from "@firebase/util";
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

const useStyles = makeStyles((theme) => ({
  mdBox: {
    marginLeft:6.5
  },
  [theme.breakpoints.down('xs')]: {
    mdBox: {
      marginLeft:6.5
    },
  },
  [theme.breakpoints.down('sm')]: {
    mdBox: {
      marginLeft:6.5
    },
  },
  [theme.breakpoints.up('md')]: {
    mdBox: {
      marginLeft:6.5
    },
  },
  [theme.breakpoints.up('lg')]: {
    mdBox: {
      marginLeft:6.5
    },
  },
  [theme.breakpoints.up('xl')]: {
    mdBox: {
      marginLeft:6.5
    },
  },
}));

function Basic({setIndex, emailTrasnfer, passwordTransfer, displayNameTransfer, countDownEmail, setCountDownEmail }) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [openNotif, setOpenNotif] = useState(false)
  const [message, setMessage] = useState("")
  const [className, setClassName] = useState("modalContainer")
  const [errors, setErrors] = useState('');
  const [texte, setTexte] = useState('Vous avez reçu un mail de vérification sur votre boîte mail. Vérifiez votre adresse mail puis revenez sur cette page et cliquez sur "Continuer".')
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const notifySuccess = () => {
    toast.success('Votre compte a été vérifié avec succès!', { autoClose: 1000 , className: 'custom-success-toast'})
  }
  const getFreeTrialDoc = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${emailTrasnfer}`)
    .then(async(res) => {

      if (res.data.credentials.res.email == emailTrasnfer) {
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          verifyEmail:{
            res: true,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const updateFreeTrialPhone = async(p) =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${emailTrasnfer}`)
    .then(async(res) => {
   
      if (res.data.length != 0) {
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          phoneNumber:{
            res: p,
            completedAt: res.data?.phoneNumber?.completedAt ? res.data?.phoneNumber?.completedAt : new Date()
          }
        })
      }
    })
  }
  useEffect(() => {  
    if(seconds > 0 && countDownEmail){
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }else if(seconds <= 0){
      setCountDownEmail(false)
      setSeconds(59)
    }

  }, [seconds, countDownEmail]);
  const sendCode = async() =>{
    const email = localStorage.getItem('email')
    const password = localStorage.getItem('password')
    const auth = getAuth()
      const  user  = await signInWithEmailAndPassword(auth, emailTrasnfer, passwordTransfer)
   
      await sendEmailVerification(user.user)
      setSeconds(59)
      setCountDownEmail(true)
  }
  const createUser = async()=>{
   const password =  localStorage.getItem('password')
   const displayName =  localStorage.getItem('displayName')
   const email =  localStorage.getItem('email')
    await axios
    .post(`${process.env.REACT_APP_API_DEV}/athletes/create-athlete`,
    {
        displayName: displayNameTransfer,
        email: emailTrasnfer,
        password: passwordTransfer
    })
    .then(async(res) => {
      const g = res.data

      dispatch({ type: 'SET_USER', payload: g });
  
      await axios
      .put(`${process.env.REACT_APP_API_DEV}/athletes/verifyEmail/${g._id}`)
        setMessage("Votre compte a été vérifié avec succès!")
        setClassName("modalContainer")
        setOpenNotif(true)
        setIsTimedOut(false);
        const timeoutId = setTimeout(async() => {
        setIsTimedOut(true);
        setOpenNotif(false)
        await getFreeTrialDoc()
        setIndex(2)
      }, 1000);
    })
  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${emailTrasnfer}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        if(res.data?.startAt?.res){
          if (res.data.activities.res.length != 0) {
            if (res.data?.gender?.res) {
              if (res.data?.selfie?.res) {
                if (res.data?.cin?.res) {
                  if (res.data?.gym?.completedAt) {
                    if (res.data?.offer?.completedAt){
                      setIndex(11)
                    }else{
                      setIndex(10)
                    }
                  }else{
                    setIndex(9)
                  }
                  
                }else{
                  setIndex(8)
                }
                
              }else{
                setIndex(7)
              }
              
            }else{
              setIndex(6)
            }
           }else{
            setIndex(5)
           }
        }else{
          setIndex(4)
        }
       
      }
    })
  }
  const getUser = async()=>{
    await axios
        .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${emailTrasnfer}`)
        .then(async(res) => {
          const g = res.data
          
          if(g.email == undefined){
            await createUser() 
          }else if(g.email != undefined && g.isEmailVerified == false){
            dispatch({ type: 'SET_USER', payload: g });

            await axios
          .put(`${process.env.REACT_APP_API_DEV}/athletes/verifyEmail/${g._id}`)
          .then(  async()=>{
            setIsTimedOut(false);
            setMessage("Votre compte a été vérifié avec succès!")
            setClassName("modalContainer")
            setOpenNotif(true)
            const timeoutId = setTimeout(async() => {
            setIsTimedOut(true);
            setOpenNotif(false)
            setIndex(2)
            await getFreeTrialDoc()
          }, 1000);
          })
          }else if(g.email != undefined && g.isEmailVerified == true && g.phoneNumber != undefined){
            dispatch({ type: 'SET_USER', payload: g });

            await navigatePages()
            await getFreeTrialDoc()
            await updateFreeTrialPhone(g.phoneNumber)
          }else if(g.email != undefined && g.isEmailVerified == true){
            dispatch({ type: 'SET_USER', payload: g });

            setIndex(2)
            await getFreeTrialDoc()
          }
        })
  }
  const checkVerification = async()=>{
    const email = localStorage.getItem("email")
    await axios
            .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${emailTrasnfer}`)
            .then(async(res) =>{
              if (res.data.userRecord.emailVerified) {
               
              // setError(true)
               getUser()
              }else{
               // console.log(res.data.emailVerified)
              //  sendCode()
          
              setTexte("Veuillez d'abord vérifier votre mail sur votre boîte mail!")
                setError(true)
               // setIndex(1)
              }

            })
  }
  const verifyEmail = async()=>{
    
    const email = localStorage.getItem("email")
    await axios
    .put(`${process.env.REACT_APP_API_DEV}/athletes/email-verification`,
    {
      email: emailTrasnfer,
    }).then((res)=>{
      if (res.data == true) {
        notifySuccess()
        setIsTimedOut(false);
        const timeoutId = setTimeout(async() => {
        setIsTimedOut(true);
        await getFreeTrialDoc()
        setIndex(2)
      }, 2000);
      }else{
        setTexte("Veuillez d'abord vérifier votre mail sur votre boîte mail!")
        setError(true)

      }
    })
  }
  const onsubmit = async() => {
   checkVerification()
  

    //setTexte("Veuillez d'abord vérifier votre mail sur votre boîte mail!")
   // setError(true)
  }

  const returnHome = ()=>{
    setError(false)
    setTexte('Vous avez reçu un mail de vérification sur votre boîte mail. Vérifiez votre boîte par mail puis revenez sur cette page et cliquez sur “Continuer”.')
    setIsTimedOut(false);
    const timeoutId = setTimeout(() => {
      setIsTimedOut(true);
      setIndex(0)
    }, 100);
  }
  const resendCode = ()=>{
    if(countDownEmail == false){

      sendCode()
      setTexte('Vous allez reçevoir un mail de vérification dans une minute sur votre boîte mail. Vérifiez votre boîte par mail puis revenez sur cette page et cliquez sur “Continuer”.')
      setIsTimedOut(false);
      setMessage("Email de vérification re-envoyé avec succès!")
      setClassName("modalContainer")
      setOpenNotif(true)
      const timeoutIdd = setTimeout(() => {
      setIsTimedOut(true);
      setOpenNotif(false)
    }, 3000);
    }

  }
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'

        >
          <Grid  item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Progress color='purple' value={0}/>
            <MDBox marginTop={-4} textAlign="center">
              <div className="iconBack">
                <img className="mailIcon" src={MailImg} alt="image description" />
              </div>
            </MDBox>
            <MDBox textAlign="center">
              <Typography color={"#000000"} variant='h4' justifyContent='center' fontWeight='bold'>
              Vérifiez votre addresse mail
              </Typography>
            </MDBox>

            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                  marginTop={1}
                >
                <MDBox
                 textAlign="center"
                 marginTop={2}
                 >
                  <p className={`text ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>
                <MDBox marginTop={1} component='form' role='form'>
                  <MDBox marginTop={3} textAlign='center'>
                    <MDButton
                    className={`ButtonRounded`}
                      onClick={onsubmit}
                  //    sx={{ width: 230}}
                  color='purple'
                      fullWidth
                    >
                      Continuer
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign='center'>
                    <MDTypography variant='button' textTransform="none" color='error'>
                      {errors}
                    </MDTypography>
                  </MDBox>
                 
                  <MDBox marginTop={-1}>
                    <MDButton
                      variant='text'
                      color='white'
                      textAlign="left"
                      onClick={returnHome}
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        fontWeight='bold'
                        textTransform="none"
                      >
                        <p className='url' >
                        Changer mon email
                        </p>
                      </MDTypography>
                    </MDButton>
                  </MDBox>
                  <MDBox marginTop={-1}>
                    <MDButton
                      variant='text'
                      color='white'
                      onClick={resendCode}
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        fontWeight='bold'
                        textTransform="none"
                      >
                        {countDownEmail && <p className='countDown'>Vous pourrez re-envoyer le lien dans 00:{seconds}</p>}
                        {!countDownEmail && <p className='url'>Re-envoyer le lien </p>}

                      </MDTypography>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      {openNotif && <Modal setOpenModal={setOpenNotif} img={Image} msg={message} className={className}/>}
      
    </PageLayout>
  );
}

export default Basic;