/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './style.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { animated } from 'react-spring';
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import IconButton from '@mui/material/IconButton'
import AppleIcon from '@mui/icons-material/Apple';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { Modal} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
import { useSelector } from 'react-redux';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import Image from "assets/images/success.png";
import { async } from "@firebase/util";
import { ShareButtons, generateShareIcon } from 'react-share';
// firebase 
import Button from "@material-ui/core/Button";
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, submitted, setSubmitted}) {

  const user = useSelector(state => state.user);
  const [color, setColor] = useState('#000000');
  const [open, setOpen] = useState(false); 
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [welcomeMsg, setWelcomeMsg] = useState('Formulaire complété !');
  const [text1, setTexte1] = useState("Votre période d'essai est maitenant active.");
  const [text2, setTexte2] = useState("")
  const [text3, setTexte3] = useState("")
  const [text4, setTexte4] = useState("")
  const {phone, setPhone} = useState("")
  const message = "Je viens de prendre la période d’essai Yofitt à partir de www.essai.yofitt.com/free-trial ! Rejoins moi!";
  const freeTrialPeriod = useSelector(state => state.settings);
  const [activated, setActivated] = useState(false)
  const [error, setError] = useState(false);
  const [showConfi, SetShowConfi] = useState(false)
  const platformColors = {
    whatsapp: "#25D366",
    instagram: "#E4405F",
    email: "#D44638",
    sms: "#008CBA",
    telegram: "#0088cc",
    facebook: "#3b5998"
  };
  useEffect(async() => {   
    if (submitted) {
      await getFreeTrialDoc()
      await updateFreeTrialSuccess()
    }
  }, [submitted]);
  useEffect(async() => {   
    
  }, []);
  const getOrder = async(currentDate, active)=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/packs/all`)
    .then(async(res) => {
      const orders = res.data
      for (let index = 0; index < orders.length; index++) {
        const element = orders[index];
        if (element.title.includes("5 jours d'essai")) {
       //   createOrder(element, currentDate, active )
        }
      }
    })
  }

  const utiliFirebase = async()=>{

    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${user.email}`)

        .then(async(res) => {

          if (res.data.length == 0) {

        //   sendSmsCredentials(res.data.password)
          }
        })
  }
  const getFreeTrialDoc = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {
      if (res.data.length != 0) {
        const dateToCompare = res.data.startAt.res;
        const today = new Date().toISOString().slice(0, 10);
        const isSameDay = dateToCompare.slice(0, 10) === today;
  
        if (isSameDay) {

          if (res.data.credentials.isNewAccount == false) {
            setTexte1("Votre période d'essai est maitenant active.")
            setTexte3("Bon sport et have fun,")
            setTexte4("L'équipe Yofitt")
          }else{
            setTexte1("Votre période d'essai est maitenant active. Veuillez vous connecter par email.")
            setTexte2("Votre mot de passe vous a été envoyé par email et sms.")
            setTexte3("Bon sport et have fun,")
            setTexte4("L'équipe Yofitt")
            await sendSmsCredentials()
          }
          const currentDate = new Date()
          const futureDate = new Date();
          futureDate.setDate(currentDate.getDate() + freeTrialPeriod);
        //  await getOrder(currentDate, true)
          await updateUser(futureDate)
        }else{
          const trialStartDate = new Date(res.data.startAt.res).toLocaleDateString('en-GB');
          if (res.data.credentials.isNewAccount == false) {
            setTexte1(`Votre période d'essai s'activera automatiquement le ${trialStartDate}.`)
            setTexte3("À très vite!")
            setTexte4("L'équipe Yofitt")
          }else{
            setTexte1(`Votre période d'essai s'activera automatiquement le ${trialStartDate}.`)
            setTexte2("Votre mot de passe vous a été envoyé par email et sms.")
            setTexte3("À très vite!")
            setTexte4("L'équipe Yofitt")
            await sendSmsCredentials()
          }
          const currentDate = new Date()
       //   await getOrder(currentDate, false)
        }
      }
    })
  }
  const sendSmsCredentials = async() =>{

    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.credentials.isNewAccount) {
        await axios
          .post(`${process.env.REACT_APP_API_DEV}/sms/sendCredentials`,
          {
              to: res.data.phoneNumber.res,
              credentials:{
                email: user.email,
                password: res.data.credentials.res.temporaryPassword
              }
          },)
          .then(async(res) => {
         
          })
      }
    })

   
  }
  const createOrder = async(pack, currentDate, active)=>{
    await axios
        .post(
          `${process.env.REACT_APP_API_DEV}/orders/create-users-order`,
          {
           userId: user._id,
           pack: pack,
           purchasedRef: user.email,
           purchasedOn: "web",
           creationDate: currentDate,
           purchaseType: "freeTrial",
           activated: active
          },
          
        )
  }
  const updateUser = async(date) =>{
  await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${user.email}`)
    .then(async(res) => {
      const g = res.data
      await axios
    .patch(`${process.env.REACT_APP_API_DEV}/athletes/${user._id}`,
    {
      email: user.email,
      trialExpiryDate: date,
      subscription:{"expiryDate": date,"level":4},
    },).then((res)=>{

     setSubmitted(false)
    })
    })
  }

  const handleButtonAppleClick = () => {
    window.open('https://apps.apple.com/us/app/yofittapp/id1589787539', '_blank');
  };
  const openGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.yofittapp', '_blank');
  };
  const updateFreeTrialSuccess= async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {
  
      if (res.data.length != 0) {
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          successAt: new Date(),
          status: "completed"
        })
      }
    })
  }
  const handleCloseModal = ()=>{
    setOpen(false)
  }
  const shareMessage = ()=>{
    setOpen(true)
  }
  const handleShare = (platform) => {
    const instagramMessage = "Je viens de prendre la période d’essai Yofitt à partir de"
    switch (platform) {
      case "whatsapp":
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        break;
        /**case "instagram":
        window.open(`https://www.instagram.com/add/${message}`);
        break; */
      
      case "gmail":
        
      window.location.href = `mailto:?subject=Check out Yofitt free trial&body=${message}`;
        break;
      case "messenger":
        window.open('https://www.facebook.com/sharer/sharer.php?quote=' + encodeURIComponent(message));
        break;
      case "telegram":
        window.open(`https://t.me/share/url?url=${message}`);
        break;
      default:
        break;
    }
    setOpen(false)
  };
  return (
    <PageLayout>
      

      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
         
       
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <MDBox textAlign="center" marginTop={-6} >
          <MDBox textAlign="center" >
              <div className="iconBack">
                <img className="successIcon" src={Image} alt="image description" />
              </div>
            </MDBox>
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold' marginTop={-10}>
           {welcomeMsg}
            </Typography>
          </MDBox>
          <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={1}
                >
                <MDBox textAlign="center"
                 marginTop={2}
                >
                  <p className={`textbottom`}>{text1}</p>
                </MDBox>
                <MDBox textAlign="center"
                 marginTop={0}
                >
                  <p className={`textbottom`}>{text2}</p>
                </MDBox>
                <MDBox textAlign="center"
                 marginTop={2}
                >
                  <p className={`textbottom`}>{text3}</p>
                </MDBox>
                <MDBox textAlign="center"
                 marginTop={0}
                >
                  <p className={`textbottom`}>{text4}</p>
                </MDBox>
                <MDBox marginTop={1} component='form' role='form'>
                
                 <MDBox textAlign="center" mb={2}>

                  </MDBox>

                  <MDBox marginTop={2} textAlign='center'>
                    <MDButton 
                      className={`ButtonRounded`}
                      onClick={handleButtonAppleClick}
                      fullWidth
                      color='black'
                      style={{ display: 'flex', alignItems: 'center' }}>
                    <AppleIcon style={{ marginLeft: -10, marginTop:'-5px' }} />
                    <span style={{ flex: 1, textAlign: 'center' }}>Télécharger sur appstore</span>
                   
                    </MDButton>
                    
                  </MDBox>
                  <MDBox marginTop={2} textAlign='center'>
                    <MDButton 
                      className={`ButtonRounded`}
                      onClick={openGooglePlay}
                      fullWidth
                      variant="outlined"
                      color='black'
                      style={{ display: 'flex', alignItems: 'center' }}>
                    <GoogleIcon style={{ marginLeft: -10, marginTop:'-5px' }} />
                    <span style={{ flex: 1, textAlign: 'center' }}>Télécharger sur playstore</span>
                    </MDButton>
                  </MDBox>
                  <MDBox marginTop={1} textAlign='center'>
                  <p className={`invitation`}>Invitez des amis à prendre une période d'essai</p>
                  </MDBox>
                  <MDBox marginTop={1} textAlign='center'>
                    <MDButton
                      onClick={shareMessage}
                      fullWidth
                      color='purple'
                    >
                      Inviter
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>

        <Modal open={open} onClose={handleCloseModal}>
          <div className = "modal" style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            width: 300,
            height: 82,
            backgroundColor: "#fff",
            borderRadius: 10,
            padding: 20,
            marginBottom: 50,
            border:"none",
            justifyContent:"center",
            alignItems:"center",
     
          }}>
        <Grid container marginLeft={-4.5} justifyContent="space-evenly" spacing={2}>
          <Grid item>
                  <IconButton
            style={{
              position: 'absolute',
              backgroundColor: platformColors.whatsapp,
              marginTop:-1,
              width: 40,
              height: 40,

            }}
            size='medium'
            onClick={() => handleShare("whatsapp")}

            color='white'
    >
      <WhatsAppIcon />
    </IconButton>
          </Grid>
          <Grid item>
          <IconButton
            style={{
              position: 'absolute',
              backgroundColor: platformColors.facebook,
              width: 40,
              height: 40,
              marginTop:-1,
            }}
            size='medium'
            onClick={() => handleShare("messenger")}

            color='white'
    >
      <FacebookIcon />
    </IconButton>

          </Grid>
          {/**<Grid item>
          <IconButton
            style={{
              position: 'absolute',
              backgroundColor: platformColors.instagram,
              width: 40,
              height: 40,
              marginTop:-1,

            }}
            size='medium'
            onClick={() => handleShare("instagram")}

            color='white'
    >
      <InstagramIcon />
    </IconButton>

          </Grid> */}
          
          <Grid item>
          <IconButton
            style={{
              position: 'absolute',
              backgroundColor: '#F44335',
              marginTop:-1,
              width: 40,
              height: 40,

            }}
            size='medium'
            onClick={() => handleShare("gmail")}

            color='white'
    >
      <EmailIcon />
    </IconButton>


          </Grid>
     
          <Grid item>
          <IconButton
            style={{
              position: 'absolute',
              backgroundColor: platformColors.telegram,
              width: 40,
              height: 40,
              marginTop:-1,
            }}
            size='medium'
            onClick={() => handleShare("telegram")}

            color='white'
    >
      <TelegramIcon />
    </IconButton>

          </Grid>
        </Grid>
        </div>
      </Modal>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
