import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

// @mui material components
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import IconButton from '@mui/material/IconButton'
// react-router-dom components
import { Link } from 'react-router-dom'
import MDAvatar from 'components/MDAvatar'
// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import boxShadow from 'assets/theme/functions/boxShadow'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',

    fontSize: 14,
  },
}))
function DefaultProjectCard({
  image,
  label,
  title,
  description,
  action,
  authors,
}) {
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement='bottom'>
      <MDAvatar
        src={media}
        alt={name}
        size='xs'
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: 'pointer',
          position: 'relative',

          ml: -1.25,
          '&:hover, &:focus': {
            zIndex: '10',
          },
        })}
      />
    </Tooltip>
  ))

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        boxShadow: 'none',
       // overflow: 'visible',
      }}
    >
      <MDBox position='relative' height='100%' shadow='xl' borderRadius='xl'>
        <MDTypography variant='h5' color='darkgreen'>
      
          <CardMedia
            src={`${image}`}
            component='img'
            title={title}
            sx={{
              maxWidth:'100%',
              minWidth : '100%',
              margin: 0,
              objectFit: 'cover',
             objectPosition: 'center',
              height: '280px',
            }}
          />
             <MDTypography textAlign='right' color='error'>
            <IconButton
              style={{ position: "absolute", top: -10, right: -10, backgroundColor:"#F44335", border: "4px white solid", width:40, height:40}}
              size='medium'
              onClick={action.route}
              aria-label='delete'
              color='white'
            >
              <Tooltip title='Delete image' placement='bottom'>
                <ClearOutlinedIcon />
              </Tooltip>
            </IconButton>
          </MDTypography>
        </MDTypography>
      </MDBox>
      {/** 
       *       <MDBox mt={1} mx={0.5}>
        <MDTypography
          variant='button'
          fontWeight='regular'
          color='text'
          textTransform='capitalize'
        >
          {label}
        </MDTypography>
        {/* <MDBox mb={1}>
          {action.type === "internal" ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          )}
        </MDBox> 
                <MDBox mb={3} lineHeight={0}>
          <MDTypography variant='button' fontWeight='light' color='text'>
            {description}
          </MDTypography>
        </MDBox>
        */}
        

        {/*  <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {action.type === "internal" ? (
            <MDButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </MDButton>
          ) : (
            <MDButton
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="small"
              color={action.color}
            >
              {action.label}
            </MDButton>
          )}
          <MDBox display="flex">{renderAuthors}</MDBox>
        </MDBox>
      </MDBox>
      */}

    </Card>
  )
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
}

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // action: PropTypes.shape({
  //  type: PropTypes.oneOf(["external", "internal"]),
  //  route: PropTypes.string.isRequired,
  //  color: PropTypes.oneOf([
  //    "primary",
  //    "secondary",
  //    "info",
  //    "success",
  //   "warning",
  //    "error",
  //    "light",
  //    "dark",
  //   "white",
  //  ]).isRequired,
  //  label: PropTypes.string.isRequired,
  // }).isRequired,
  action: PropTypes.shape({
    route: PropTypes.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
}

export default DefaultProjectCard

