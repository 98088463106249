import HelpIcon from '@mui/icons-material/Help';
import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
    palette: {
      purple: {
        main: '#5D1EF7',
      },
      green: {
        main: '#26D98C',
      },
      blue: {
        main: '#2699FB'
      }
  }});
const Help = ({message}) => {
  const [linkWtsp, setLinkWtsp] = useState("") 

  const handleContactClick = () =>{
    window.open(`${linkWtsp}?text=${message}`);
  }
  const getSettings = async()=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/settings`)
    .then(async(res) => {
        setLinkWtsp(res.data[0].trial.whatsapp)

    })
  }
  useEffect(() => {   
    getSettings()
  },[]);
  return (
    <ThemeProvider theme={theme}>
    <div style={{textAlign: 'center',}}>         
    <span onClick={handleContactClick} 
    style={{ 
      fontSize: '10px',
       color: '#2699FB', 
       fontWeight: 'bold', 
       display: 'inline-block', 
       textDecoration: 'underline',
       marginTop: '10px'
        }}>
    Vous avez des questions ou un bug? Contactez nous sur WhatsApp en cliquant ici.
    <HelpIcon fontSize="small" color="blue" sx={{ verticalAlign: 'text-bottom', marginBottom: '-4px', marginLeft: 0.5 }} />
    </span>
    </div>
</ThemeProvider>
  )
}

export default Help