/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import React, { useRef} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import CloudActivities from "../components/CloudActivities/CloudActivities";
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import 'react-toastify/dist/ReactToastify.css';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PhoneImg from "../../../assets/images/Mobile-cuate.png"
import { toast } from 'react-toastify'
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import VerificationCodeInput from "react-verification-code-input";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Progress from "components/MDProgress"
// axios
import axios from "axios";
import { useSelector } from 'react-redux';
// firebase 
import { app } from  '../../../firebaseConfig';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { CListGroup } from "@coreui/react-pro";
import OtpImg from "../../../assets/images/otp.png"
import "./styles.css";
import { EqualizerSharp } from "@material-ui/icons";
import { ElectricScooter } from "@mui/icons-material";
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function remainingActivities(tab){

  let nbr = tab.length%3
  let tab2 = []
  for (let index = 0; index < nbr; index++) {
    tab2.push(tab[tab.length - (index+1)])
  }

  return tab2
}
function catMaking(tab){
  let nbr = remainingActivities(tab).length
  for (let index = 0; index < nbr; index++) {
    tab.splice(tab.length - (index + 1), 1)
  }

  return tab
}
function Basic({setIndex, setSubmitted}) {
  const user = useSelector(state => state.user);
  const [errors, setErrors] = useState('');
  const [error, setError] = useState(false);
  const inputRefs = useRef([...Array(4)].map(() => React.createRef()));
  const [values, setValues] = useState(Array(4).fill(""));
  let verifyNum = 0
  const [texte, setTexte] = useState('Veuillez choisir vos 3 activités préférées. Ceci ne restreint en aucun cas les activités que vous pourrez faire pendant votre période d’essai.')
  const [categories,setCategories]= useState([])
  const [selectedActivities, setSelectedActivities] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [remainingCat, setRemainingCat] = useState([])
  const colors = {"green": "#26D98C", "light":"#EFF4F2", "purple":"#5D1EF7"}
  const getCategories = async () => {
    await  axios
        .get(`${process.env.REACT_APP_API_DEV}/activities/`)
        .then((res) => {
          const cat= res.data

          
          setRemainingCat(remainingActivities(cat))
          setCategories(cat)
         // setAutre(cat[cat.length - 1])
        //  cat.pop()
        })
  }
  const disableActivities = ()=>{
    for (let index = 0; index < categories.length; index++) {
   //   console.log(selectedActivities.indexOf(categories[index]._id))
      if (selectedActivities.indexOf(categories[index]._id) == -1) {
        document.getElementById(categories[index]._id).disabled = true
        document.getElementById(categories[index]._id).style.borderColor = colors.light
        document.getElementById(categories[index]._id).style.color = colors.light
      }
    }
    for (let index = 0; index < remainingCat.length; index++) {
      //   console.log(selectedActivities.indexOf(categories[index]._id))
         if (selectedActivities.indexOf(remainingCat[index]._id) == -1) {
           document.getElementById(remainingCat[index]._id).disabled = true
           document.getElementById(remainingCat[index]._id).style.borderColor = colors.light
           document.getElementById(remainingCat[index]._id).style.color = colors.light
         }
       }
  }
  const enableActivities = ()=>{
    for (let index = 0; index < categories.length; index++) {
     // console.log(selectedActivities.indexOf(categories[index]._id))
      if (selectedActivities.indexOf(categories[index]._id) == -1) {
        document.getElementById(categories[index]._id).disabled = false
        document.getElementById(categories[index]._id).style.borderColor = colors.light
        document.getElementById(categories[index]._id).style.color = "black"
      }
    }
    for (let index = 0; index < remainingCat.length; index++) {
      // console.log(selectedActivities.indexOf(categories[index]._id))
       if (selectedActivities.indexOf(remainingCat[index]._id) == -1) {
         document.getElementById(remainingCat[index]._id).disabled = false
         document.getElementById(remainingCat[index]._id).style.borderColor = colors.light
         document.getElementById(remainingCat[index]._id).style.color = "black"
       }
     }
  }
  function filter(event, id){
    event.preventDefault()
    var element = document.getElementById(id);
    var computedStyles = window.getComputedStyle(element);
    var borderColor = computedStyles.borderColor;
    var borderColorRgb = borderColor.match(/\d+/g).map(function(value) {

      return parseInt(value);
    });

    if (borderColorRgb[0] === 93 && borderColorRgb[1] === 30 && borderColorRgb[2] === 247 && selectedActivities.length<=3) {
      document.getElementById(id).style.borderColor = colors.light
      selectedActivities.splice(selectedActivities.indexOf(id), 1)
      enableActivities()
    }else {
      if (selectedActivities.length<3) {
        selectedActivities.push(id)
        document.getElementById(id).style.borderColor = colors.purple;
      }
   
    }
  
   if (selectedActivities.length == 3) {
    disableActivities()
   }else if(selectedActivities.length > 0){
    setDisableButton(true)
   }else{
    setDisableButton(false)
   }
    }

  useEffect(() => {   
    getCategories()
  }, []);
  const updateFreeTrialActivities= async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          activities: {
            res: selectedActivities,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        if (res.data?.birthday?.res) {
          const selectedDate = new Date(res.data?.birthday?.res); // Convert moment object to JavaScript Date object
          const today = new Date();
          const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
          const differenceInMonths = today.getMonth() - selectedDate.getMonth();
          const differenceInDays = today.getDate() - selectedDate.getDate();
          if (differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0)) {
            if (res.data?.gender?.res) {
              if (res.data?.gym?.completedAt) {
                  if(res.data?.rules?.completedAt){
                    if(res.data?.refunded?.completedAt){
                      setSubmitted(true)
                      setIndex(13)
                    }else{
                      setIndex(12)
                    }
                  }else{
                    setIndex(11)
                  }
                
                
              }else{
                setIndex(9)
              }

          }else{
            setIndex(8)
          }
          }else{
            setIndex(7)
          }
        }else{
          setIndex(7)
        }
           }
      
    })
  }
  const onsubmit = async () => {

    await updateFreeTrialActivities()
    await navigatePages()
   
  //setIndex(1)
  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='99vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'

        >
         
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={39}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <Typography color={"#000000"} variant='h4' textAlign={'center'} justifyContent='center' marginTop={1} fontWeight='bold'>
              Mes activités favorites
            </Typography>
         <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                  marginTop={2}
                >
                <MDBox textAlign="center">
                  <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>
                <CloudActivities cat={categories} filter={filter} remaining={remainingCat} />
                <MDBox marginTop={2} component='form' role='form'>
               
                  <MDBox marginTop={4} textAlign='center'>
                    {!disableButton && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }
                   {disableButton && 
                     <MDButton
                     onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                   color='purple'
                   >
                     Continuer
                   </MDButton>
                    }
                  </MDBox>
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;