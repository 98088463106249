/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import IconButton from '@mui/material/IconButton'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Image from "assets/images/success.png";
import { DatePicker, Space } from 'antd';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {
  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState('Etes-vous déjà inscrit(e) à une salle de sport ?');
  const [disableButton, setDisableButton] = useState(false)
  const [ifOui, setIfOui] = useState(false)
  const [showButton, setShowButtons] = useState(true)
  const [text1, setTexte1] = useState("Complétez le formulaire avec les bonnes informations pour obtenir votre période d'essai");
  const [gymName, setGymName] = useState("")
  const [date, setDate] = useState(null)
  const user = useSelector(state => state.user);
  const colors = {"green": "#26D98C", "light":"#EFF4F2", "purple":"#5D1EF7"}
  const [error, setError] = useState(false);
  const { RangePicker } = DatePicker;
  useEffect(() => {   
    if(gymName != "" && ifOui){
        setDisableButton(true)
    }else if(ifOui && gymName == ""){
        setDisableButton(false)
    }
  }, [gymName, ifOui]);
  const onGymNameChange = (e)=>{
    setError(false)
    setTexte1("Complétez le formulaire avec les bonnes informations pour obtenir votre période d'essai")
    setGymName(e.target.value);
    
  }
  const updateFreeTrialPhone = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          gym:{
            res: {
              isSubscribed: ifOui,
              gymName: ifOui ? gymName : null,
              expiryDate: date,
            },

            completedAt: new Date()
          }
        }).then(()=>{

            if(res.data?.rules?.completedAt){
              if(res.data?.refunded?.completedAt){
                setSubmitted(true)
                setIndex(13)
              }else{
                setIndex(12)
              }
            }else{
              setIndex(11)
            }
          
        } )
      }
    })
  }
  const onChange = (value) => {

    setDate(value.format())
   // console.log(value.format())
   // console.log("****",value)
   // console.log("show ",startDate)
  };
  const onOk = (value) => {
    // console.log('onOk: ', value);
   };
  const handleSelect = (event)=>{
    event.preventDefault()
    var element = document.getElementById(event.target.id);
     var computedStyles = window.getComputedStyle(element);
     var borderColor = computedStyles.borderColor;
     var borderColorRgb = borderColor.match(/\d+/g).map(function(value) {
       return parseInt(value);
     });
 
     if (borderColorRgb[0] === 38 && borderColorRgb[1] === 217 && borderColorRgb[2] === 140) {
       document.getElementById(event.target.id).style.borderColor = colors.light
       setDisableButton(false)
     }else {
      document.getElementById(event.target.id).style.borderColor = colors.purple;
      
      if (event.target.id == "oui") {
        setDisableButton(false)
        setIfOui(true)
        setShowButtons(false)
        document.getElementById("non").style.borderColor = colors.light
      }else{
        setDisableButton(true)
        setIfOui(false)
        document.getElementById("oui").style.borderColor = colors.light
      }
     }
 }
const onsubmit = async()=>{
   await updateFreeTrialPhone()
}
  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Progress color='purple' value={62.4} marginTop={-1}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center" marginTop={ 2} >

          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold' >
           {welcomeMsg}
            </Typography>
          </MDBox>
          <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={1}
                >
                <MDBox textAlign="center"
                 marginTop={2}
                >
                  <p className={`textbottom ${error ? 'error' : ''} `}>{text1}</p>
                </MDBox>

                <MDBox marginTop={2} component='form' role='form'>
                {showButton && 
                    <MDBox marginTop={3} mb={2}>

                    <div className='gender-buttons-contaner' >
                        <button id="oui" className="gender-buttons" onClick={(e)=>handleSelect(e)}>Oui</button>
                        <button id="non" className="gender-buttons" onClick={(e)=>handleSelect(e)}>Non</button>
                    </div>
                        
                        </MDBox>
                }

                 {ifOui && 
                  <MDBox mb={2}>
                   <MDBox mb={2}>
                   <MDInput
                    required
                     className={`inputRounded`}
                     type='text'
                     label='Nom de la salle de sport'
                     onChange={onGymNameChange}
                     fullWidth
                   />
                   </MDBox>
                   <MDBox mb={2}>
                     <DatePicker 
                     placeholder='Date de fin de votre abonnement à cette salle' 
                     className={`datepicker`}
                     style={{ width: '100%', height: 45, borderRadius: 160}}
                     onChange={onChange} 
                     onOk={onOk} 
                     format='MMM Do, YYYY hh:mm a'
                  
                     />
                  </MDBox>
                  </MDBox>
                 }
               
                 <MDBox marginTop={3} textAlign='center'>
                   {!disableButton && 
                    <MDButton
                   // onClick={onsubmit}
                    fullWidth
                  //  sx={{width:190}}
                    color='light'
                  >
                    Continuer
                  </MDButton>
                   }
                  {disableButton && 
                    <MDButton
                    onClick={onsubmit}
                    fullWidth
                  //  sx={{width:190}}
                  color='purple'
                  >
                    Continuer
                  </MDButton>
                   }
                 </MDBox>
              
               </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
