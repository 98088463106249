/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {
  const [rememberMe, setRememberMe] = useState('');
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const maxTrialTickets = useSelector(state => state.maxtrialtickets);

  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState("Votre ville");
  const [noneDisp, setNoneDisp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [value, setValue] = useState('');
  const [otherCity, setOtherCity] = useState('')
  const [texte, setTexte] = useState("Veuillez choisir votre ville principale")
  const [textError1, setTextError1] = useState("")
  const user = useSelector(state => state.user);
  const cities = useSelector(state => state.cities);
  const [displayedCities, setDisplayedCities] = useState("")
  const [clicksNumber, setClicksNumber] = useState(1)
  const [selectedCity, setSelectedCity] = useState("")
  const [error, setError] = useState(false);
  useEffect(async() => {  
    
   if(value.length != 0 && value != "Other"){
    setIsChecked(true)
    setIsOtherChecked(false)
   }else if(value == "Other"){
    setIsChecked(false)
    setIsOtherChecked(true)
   }else{
    setIsChecked(false)
    setIsOtherChecked(false)
   }
   if (value == "Other" && otherCity.length != 0) {
    setIsChecked(true)
   }
   if (displayedCities.length == 0) {
   let c = ""
    for (let index = 0; index < cities.length - 1; index++) {
      c += cities[index] 
      c += ", " 
    }
    c += cities[cities.length - 1] 
    setDisplayedCities(c)
   }
  }, [value, otherCity]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
          if(res.data?.startAt?.res){
            if (res.data.activities.res.length != 0) {
              if (res.data?.birthday?.res) {
                const selectedDate = new Date(res.data?.birthday?.res); // Convert moment object to JavaScript Date object
                const today = new Date();
                const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
                const differenceInMonths = today.getMonth() - selectedDate.getMonth();
                const differenceInDays = today.getDate() - selectedDate.getDate();
                if (differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0)) {
                  if (res.data?.gender?.res) {
                    if (res.data?.gym?.completedAt) {
                        if(res.data?.rules?.completedAt){
                          if(res.data?.refunded?.completedAt){
                            setSubmitted(true)
                            setIndex(13)
                          }else{
                            setIndex(12)
                          }
                        }else{
                          setIndex(11)
                        }
                      
                      
                    }else{
                      setIndex(9)
                    }
  
                }else{
                  setIndex(8)
                }
                }else{
                  setIndex(7)
                }
              }else{
                setIndex(7)
              }
             }else{
              setIndex(5)
             }
          }else{
            setIndex(4)
          }
        
        
       
      }
    })
  }
  
    const updateFreeTrialCity = async(city) =>{
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
      .then(async(res) => {

        if (res.data.length != 0) {
          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
          {
            city:{
              res: city,
              completedAt: new Date()
            }
          }).then()
        }
      })
    }
    const updateUser = async(city)=>{
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${user.email}`)
      .then(async(res) => {
        const g = res.data
        await axios
      .patch(`${process.env.REACT_APP_API_DEV}/athletes/${g._id}`,
      {
        email: user.email,
        city: city,
      },).then((res)=>{

      })
      })
    }
  const onsubmitt = async () => {
    //await updateFreeTrialRules()
    setIsOtherChecked(false)

   if (clicksNumber == 1) {
    if (cities.includes(value)) {

      await updateFreeTrialCity(value)
      await updateUser(value)
      await navigatePages()
    } else {

      setTexte("")
      setError(true)
      setClicksNumber(2)
      if (value == "Other") {
        await updateFreeTrialCity(otherCity)
        await updateUser(otherCity)
      }else{
        await updateFreeTrialCity(value)
        await updateUser(value)
      }
      
    }
   }else{
    await navigatePages()
   }

  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={23.4}/>
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
          <MDBox textAlign="center" marginTop={0}>
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={3}
              >
                <MDBox marginTop={2} component='form' role='form'>
                <MDBox textAlign="center">
                  <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>
                <MDBox marginTop={2} >
                  {error && 
                  <MDBox marginTop={5} textAlign="center">
                    <p className={`textbottom ${error ? 'error' : ''} `}>Oups! Nous sommes désolés, nous ne sommes pas encore présents sur cette ville!
                    </p>
                    <p className={`textbottom ${error ? 'error' : ''} `}>Nous reviendrons vers vous dès que nous y établirons nos premiers partenariats.</p>       
                      <br />
                  <p className={`textbottom ${error ? 'error' : ''} `}>Les villes actuellement couvertes sont: {displayedCities}.</p>
                  <br />
                  <p className={`textbottom ${error ? 'error' : ''} `}>Si vous désirez tout de même prendre votre période d'essai, veuillez cliquer sur <span style={{fontWeight:"bold"}}>"CONTINUER"</span>.</p>
                  </MDBox>
                  }
                  {!error &&
                        <FormControl>
                        <RadioGroup
                        
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel  value="Casablanca" control={<Radio   />} label={<span style={{ color: 'black' }}>Casablanca</span>} />
                            <FormControlLabel value="Mohammedia" control={<Radio />} label={<span style={{ color: 'black' }}>Mohammedia</span>} />
                            <FormControlLabel value="Rabat" control={<Radio />} label={<span style={{ color: 'black' }}>Rabat</span>} />
                            <FormControlLabel value="Salé" control={<Radio />} label={<span style={{ color: 'black' }}>Salé</span>} />
                            <FormControlLabel value="Marrakech" control={<Radio />} label={<span style={{ color: 'black' }}>Marrakech</span>} />
                            <FormControlLabel value="Agadir" control={<Radio />} label={<span style={{ color: 'black' }}>Agadir</span>} />
                            <FormControlLabel value="Tanger" control={<Radio />} label={<span style={{ color: 'black' }}>Tanger</span>}/>
                            <FormControlLabel value="Other" control={<Radio />} label={<span style={{ color: 'black' }}>Autre ville</span>} />
                        </RadioGroup>
                        </FormControl>
                  }

                </MDBox>
                {isOtherChecked && 
                      <MDBox mb={2}>
                      <MDInput
                        className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                        type='text'
                        label='Votre ville'
                        onChange={(e)=>setOtherCity(e.target.value)}
                        fullWidth
                      />
                    </MDBox>
                    }
               

                  <MDBox marginTop={3}>
                  {!isChecked && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }
                   {isChecked && 
                     <MDButton
                     className={`ButtonRounded ${noneDisp ? 'noneDisp' : ''} `}
                       onClick={onsubmitt}
                       fullWidth
                       color='purple'
                     >
                       Continuer
                     </MDButton>
                    }
                    
                  </MDBox>

            
                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;