/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PhoneImg from "../../../assets/images/Mobile-cuate.png"
import { toast } from 'react-toastify'
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Progress from "components/MDProgress"
// axios
import { useSelector } from 'react-redux';
import axios from "axios";
// firebase 
import { app } from  '../../../firebaseConfig';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { CListGroup } from "@coreui/react-pro";
import { async } from "@firebase/util";
import { useDispatch } from 'react-redux';
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setPhoneNumberTransfer, setCountDown }) {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState()
  const [errors, setErrors] = useState('');
  const [disp, setDisp] = useState(false)
  const [error, setError] = useState(false);
  const [texte, setTexte] = useState('Votre numéro de téléphone nous permettra de vous contacter en cas de changements liés à vos réservations.')
  const user = useSelector(state => state.user);

  useEffect(() => {   
   
  }, []);
  const onPhoneChange = (e) => {
    setTexte("Votre numéro de téléphone nous permettra de vous contacter en cas de changements liés à vos réservations.")
    setError(false)
    setPhoneNumber(e)
    if (e.substring(0, 3) == "212") {
     if (e.length != 12) {

       setDisp(false)
   
     }else{
      // sendVerificationCode()
      setDisp(true)
     
     }
    }else if(e.substring(0, 2) == "33"){
     if (e.length != 11) {
      
       setDisp(false)
 
       
     }else{

      setDisp(true)
    
     }
    }else if(e.substring(0, 2) == "34"){
      if (e.length != 11) {

        setDisp(false)

      }else{
        setDisp(true)
     
      }
    }else if(e.substring(0, 2) == "44"){
      if (e.length != 12) {
  
        setDisp(false)

      }else{
       // sendVerificationCode()
       setDisp(true)
     
      }
    }else{
      setDisp(false)
    }

  };

  const updateFreeTrialPhone = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        const num = "+" + phoneNumber
        let date 
        if (res.data?.phoneNumber?.res == num) {
          date = res.data?.phoneNumber?.completedAt
        }else{
          date = new Date()
        }
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          phoneNumber:{
            res: "+" + phoneNumber,
            completedAt: date
          }
        })
      }
    })
  }
  const sendVerificationCode = async() => {
    const num = "+" + phoneNumber
    setPhoneNumberTransfer(num)
    await 
    axios.post(`${process.env.REACT_APP_API_DEV}/sms/sendverifycode`,
    {
      to: num,
      channel: "sms",
    },).then(async(res)=>{
      setIndex(2)
      await updateFreeTrialPhone()
      setCountDown(true)
    })
  }
  const getUserPhone = async () =>{
    const num = "+" + phoneNumber
    setPhoneNumberTransfer(num)

    await 
    axios.get(`${process.env.REACT_APP_API_DEV}/athletes/athlete-by-phone/${num}`)
    .then((res) =>{
      if(res.data.length == 0){
       sendVerificationCode()
      }else{
        setError(true)
        setTexte("Ce numéro est déjà associé à un autre compte.")
      }
    })
  }
  const onsubmit = async () => {
   if(phoneNumber == undefined){
    setTexte("Veuillez entrer un numéro de téléphone")
    setError(true)
   }else{

    if (phoneNumber.substring(0, 3) == "212") {
     if (phoneNumber.length != 12) {
       setTexte("Veuillez entrer un numéro de téléphone valide")
       setError(true)
     }else{
      // sendVerificationCode()
      getUserPhone()
     
     }
    }else if(phoneNumber.substring(0, 2) == "33"){
     if (phoneNumber.length != 11) {
       setTexte("Veuillez entrer un numéro de téléphone valide")
       setError(true)
       
     }else{

     getUserPhone()
    
     }
    }else if(phoneNumber.substring(0, 2) == "34"){
      if (phoneNumber.length != 11) {
        setTexte("Veuillez entrer un numéro de téléphone valide")
        setError(true)
      }else{
      getUserPhone()
     
      }
    }else if(phoneNumber.substring(0, 2) == "44"){
      if (phoneNumber.length != 12) {
        setTexte("Veuillez entrer un numéro de téléphone valide")
        setError(true)
      }else{
       // sendVerificationCode()
       getUserPhone()
     
      }
    }else{

    }
   }
  
  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='99vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
        
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'

        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={7.8} />
          <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
            {/**
             *             <MDBox textAlign="center">
              <div className="iconBack">
                <img className="mailIcon" src={PhoneImg} alt="image description" />
              </div>
            </MDBox>
             */}
             
              <MDBox textAlign="center" marginTop={0}>
              <Typography color={"#000000"} variant='h4' justifyContent='center' fontWeight='bold'>
               Votre numéro de téléphone
            </Typography>
            </MDBox>
               

            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                  marginTop={2}
                >
                <MDBox textAlign="center">
                  <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
                </MDBox>

                <MDBox marginTop={2} component='form' role='form'>
                
                 <MDBox mb={2} marginTop={1} >
                 <PhoneInput
                    preferredCountries={['ma','fr']}
                    countryCodeEditable={false}
                    country={'ma'}
                    placeholder="Entrez votre numéro de tel"
                    value={phoneNumber}
                    onChange={onPhoneChange}
                    containerClass="my-container-class"
                    inputClass="my-input-class"
                    containerStyle={{
                      width:'100%'
                    }}
                    inputStyle={{
                      borderRadius:160,
                      width:'100%',
                      height: 46,
                    }}
                    buttonStyle={{
                      borderTopLeftRadius:160,
                      borderBottomLeftRadius:160,
                      backgroundColor:'#EFF4F2',
                      height: 46,
                      width:45
                    }}
                />
                  </MDBox>

                  <MDBox marginTop={3} textAlign='center'>
                
                    {!disp && 
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }
                   {disp && 
                        <MDButton
                        onClick={onsubmit}
                        fullWidth
                        color='purple'
                      >
                        Continuer
                      </MDButton>
                    }
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign='center'>
                    <MDTypography variant='button' textTransform="none" color='error'>
                      {errors}
                    </MDTypography>
                  </MDBox>
                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;