import './styles.css'

import React from 'react'

import { useEffect, useState } from "react";
import ProfilesList from "examples/Lists/ProfilesList";
import { Avatar, Card, Skeleton, Switch } from 'antd';
import noImg from "../../../../assets/images/no-image.jpg"

const CloudGyms = ({cat, filter}) => {
const  itemsPerLine =  Math.ceil(cat.length / 1)
const { Meta } = Card;
  return (
    <>
    <div className='navbarr'>
      <div className='navbarr-links'>
        {cat.map((c, index) => {
          if (index % itemsPerLine === 0) {
            return (
              <ul key={index} style={{listStyle: "none"}}>
                {cat.slice(index, index + itemsPerLine).map(c => (
                  <li key={c._id}>
                    <div className='links' onClick={(event) => filter(event, c._id)}>
                       {/**<Card
                            style={{
                            width: "82vw",
                            marginTop: 5,
                            borderRadius: 50,
                            }}
                            id={c._id}
                        >
                            <Meta
                            alignText = "left"
                            avatar={<Avatar src={c.imageURL ? c.imageURL: noImg} />}
                            title={c.name}
                            style={{
                               
                                fontSize:'9px'
                                }}
                            />
                            </Card> */}
                        <button className='buttonGym' id={c._id} key={c._id}>{c.name}</button>
                    </div>
                  </li>
                ))}
              </ul>
            )
          }
          return null
        })}
      
      </div>
    </div>
    {/**   <div className='navbarr2'>
            <div className='navbarr-links'>
      {remaining.map((c, index) => {

    return (
      <ul key={index} style={{listStyle: "none"}}>
        {remaining.map(c => (
          <li key={c._id}>
            <div className='links' onClick={(event) => filter(event, c._id)}>
              <button className='button' id={c._id} key={c._id}>{c.name}</button>
            </div>
      </li>
    ))}
  </ul>
)

})}
      </div>
    </div>*/}
  
    </>
  )
}
export default CloudGyms