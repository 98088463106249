/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './signin.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import HelpIcon from '@mui/icons-material/Help';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import SalesPage from "../sales-page"
import InvitationScreen from "../invitation-screen"
// firebase 
import Spinner from "../components/Spinner";
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Help from "layouts/authentication/components/help"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'


function Basic({setIndex, setEmailTransfer, setPasswordTransfer, setDisplayNameTransfert, setCountDownEmail, setSubmitted }) {
  const [rememberMe, setRememberMe] = useState('');

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [answer, setAnswer] = useState('')
  const [checked, setChecked] = useState(true)
  const [displayName, setDisplayName] = useState('');
  const [isHidden, setIsHidden] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [hasFretrial, setHasFreeTrial] = useState(false)
  const [showInvitScreen, setShowInvitScreen] = useState(false)
  const [user, setUser] = useState({});
  const [userFirebase, setUserFirebase] = useState(null);
  const [disp, setDisp] = useState(false)
  const [texte, setTexte] = useState('Entrez votre adresse mail')
  const [errors, setErrors] = useState('');
  const [password, setPassword] = useState('')
  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState("Obtenez votre période d'essai");
  const [noneDisp, setNoneDisp] = useState(false);
  const [hasInvitation, setHasInvitation] = useState(false)
  const [displaySpinner, setDisplaySpinner] = useState(false)
  const [cndpCheck, setCndpCheck] = useState(false)
  const [error, setError] = useState(false);
  const auth = getAuth();
  let today = new Date()
  today.setHours(0, 0, 0, 0)
  const provider = new GoogleAuthProvider();
  useEffect(async() => {   
  setPassword(generatePassword)
  await getFreeTrialPeriod()
  }, []);
  useEffect(() =>{
    if (answer.includes("raba") && checked && cndpCheck) {
      setDisp(true)
    }else{
      setDisp(false)
    }
  }, [answer, checked, cndpCheck])
  const onEmailChange = (e) => {
    setError(false)
    setTexte("Entrez votre adresse mail et nom d'utilisateur")
    setEmail(e.target.value.toLowerCase());
    
  };
  function onChange(value) {
    setChecked(true)
  }
  const onDisplayNameChange = (e) => {
    setError(false)
  
    setTexte("Entrez votre adresse mail et nom d'utilisateur")
    const regex = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces

    if (regex.test(e.target.value)) {
      setDisplayName(e.target.value);
    }
 
    
  };
  const onAnswerChange = (e) => {

    setAnswer(e.target.value.toLowerCase())
    
  };
  const sendCode = async() =>{
    localStorage.setItem('email', email)
    
    await axios
    .put(`${process.env.REACT_APP_API_DEV}/athletes/send-code-verification`,
    {
      email: email,
    })
  }
  const sendCredentials = async(id, p, isNewAccount, completedAt)=>{
    await axios
    .post(`${process.env.REACT_APP_API_DEV}/freetrial/`,
    {
        credentials:{
          res: {
            userId: id,
            email: email,
            temporaryPassword: p,
            displayName: displayName,
          },
          isNewAccount: isNewAccount,
          completedAt: completedAt
        },
        status: "inProgress"
    },)
    .then(async(res) => {

    })
  }
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
      })
      .catch((error) => {
        // Handle errors here.
    
      });
  };
  const getFreeTrialDoc = async(id) =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {
    
      if (res.data.length != 0) {
        
      }else{
        await sendCredentials(id, null, false, new Date())
      }
    })
  }
  const createUser = async() =>{

    await axios
    .post(`${process.env.REACT_APP_API_DEV}/athletes/create-athlete-firebase`,
    {
        displayName: displayName,
        email: email,
        password: password
    },)
    .then(async(res) => {
      setIndex(1)
      const g = res.data
   
      localStorage.setItem('password', password)
      localStorage.setItem('displayName', displayName)
      const auth = getAuth()
      const  user  = await signInWithEmailAndPassword(auth, g.email, password)

    //  await sendEmailVerification(user.user)
      await sendCredentials(user.user.uid, password, true, new Date())
      await createUserMongo(password)
      setCountDownEmail(true)
    })
  }
  const updateUserShemaEmail = async()=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${email}`)
        .catch(async function (error) {
          if (error.response) {

          } 
   
        })
        .then(async(res) => {
            if(res.data.userRecord.emailVerified){
              await axios
              .put(`${process.env.REACT_APP_API_DEV}/athletes/verifyEmail/${res.data.userRecord.uid}`)
            }
        })
  }
  const updateVerifyEmail = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {
  
      if (res.data.credentials.res.email == email) {
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          verifyEmail:{
            res: true,
            completedAt: res.data.verifyEmail?.completedAt ? res.data.verifyEmail?.completedAt : new Date()
          }
        })
      }
    })
  }
  const updateUserPhone = async(phone)=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${email}`)
    .then(async(res) => {
      const g = res.data
      await axios
    .patch(`${process.env.REACT_APP_API_DEV}/athletes/${g._id}`,
    {
      email: email,
      phoneNumber: phone,
    },)
    })
  }
  const updateBirthdayTrial = async(date)=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
            birthday: {
            res: date,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const utiliFirebase = async()=>{
    setDisplayNameTransfert(displayName)
    setEmailTransfer(email)
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${email}`)
        .catch(async function (error) {
          if (error.response) {
         
           await createUser()
          } 
  
        })
        .then(async(res) => {
          if (res.data.length == 0) {
            await createUser()
          }else{
            if(res.data.userRecord.phoneNumber){
              await getFreeTrialDoc(res.data.userRecord.uid)
            //  await updateVerifyEmail()
              await updateUserPhone(res.data.userRecord.phoneNumber)
              await updateFreeTrialPhone(res.data.userRecord.phoneNumber)
              await navigatePages()
            }else{
              await getFreeTrialDoc(res.data.userRecord.uid)
              await axios
              .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
              .then(async(res) => {
                setPasswordTransfer(res.data.credentials.res.temporaryPassword)
                const auth = getAuth()
                const  user  = await signInWithEmailAndPassword(auth, email, res.data.credentials.res.temporaryPassword)
                setIsTimedOut(false);
                setIndex(1)
              //  await sendEmailVerification(user.user)
                setCountDownEmail(true)
                })
            }

          }
        })
  }
  function generatePassword(){
    let newPassword = '';
    const possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 8; i++) {
      newPassword += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }
    return newPassword
  }
  const updateFreeTrialPhone = async(p) =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
          phoneNumber:{
            res: p,
            completedAt: res.data?.phoneNumber?.completedAt ? res.data?.phoneNumber?.completedAt : new Date()
          },
          verifyPhoneNumber:{
            res: true,
            completedAt: new Date()
          }
        })
      }
    })
  }
  const getUser = async() =>{
    setEmailTransfer(email)
    await axios
        .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${email}`)
        .then(async(res) => {
          const g = res.data
       
          setUser((prevState) => {
            return g
          })

          if(g.email == undefined){
    
            utiliFirebase()
          }
          else if(g.email != undefined){
            dispatch({ type: 'SET_USER', payload: g });
            if (g?.subscription?.level == 4) {
          
              setNoneDisp(true)
            //  setIndex(11)
              setHasFreeTrial(true)
            //  setWelcomeMsg("Vous avez déjà bénéficié d'une période d'essai. Vous pouvez maintenant souscrire à un abonnement.")
           //   setTexte("")
            //  setColor("error")
            }else if (g?.subscription?.level == 3) {
              setNoneDisp(true)
              setDisplaySpinner(false)
              setWelcomeMsg("Vous avez déjà un abonnement GOLD. Vous pouvez directement réserver des cours de l’application mobile.")
              setTexte("")
              setColor("error")
            }else{
              if (g.isEmailVerified == false) {
                await updateUserShemaEmail()
              }
              if (g.phoneNumber != undefined) {
                utiliFirebase()
              }else{
                await getFreeTrialDoc(g._id)
             //   await updateVerifyEmail()
                setIndex(1)
              }
            }
         
          }
         /**
          * else{
            await axios
            .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${email}`)
            .then(async(res) =>{
              if (res.data.emailVerified) {
                await axios
                .put(`${process.env.REACT_APP_API_DEV}/athletes/verifyEmail/${g._id}`)
                setIndex(2)
              }else{
               // console.log(res.data.emailVerified)
              //  sendCode()
                setIndex(1)
              }

            })
                
           // setIndex(1)
          } */ 
        })
  }
  const createUserMongo = async(p)=>{

     await axios
     .post(`${process.env.REACT_APP_API_DEV}/athletes/create-athlete`,
     {
         displayName: displayName,
         email: email,
         password: p
     })
     .then(async(res) => {
       const g = res.data

       dispatch({ type: 'SET_USER', payload: g });
   
     })
   }
  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {
      if (res.data.length != 0) {
        if(res.data?.verifyPhoneNumber?.res){
          if (res.data?.city?.res) {
            if(res.data?.startAt?.res && new Date(res.data?.startAt?.res) >= today){
              if (res.data.activities.res.length != 0) {
                if (res.data?.birthday?.res) {
                  const selectedDate = new Date(res.data?.birthday?.res); 
                  const today = new Date();
                  const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
                  const differenceInMonths = today.getMonth() - selectedDate.getMonth();
                  const differenceInDays = today.getDate() - selectedDate.getDate();
                  if (differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0)) {
                    if (res.data?.gender?.res) {
                      if (res.data?.gym?.completedAt) {
                          if(res.data?.rules?.completedAt){
                            if(res.data?.refunded?.completedAt){
                              setSubmitted(true)
                              setIndex(13)
                            }else{
                              setIndex(12)
                            }
                          }else{
                            setIndex(11)
                          }
                        
                        
                      }else{
                        setIndex(9)
                      }
    
                  }else{
                    setIndex(8)
                  }
                  }else{
                    setIndex(7)
                  }
                }else{
                  setIndex(7)
                }
                
               }else{
                setIndex(5)
               }
            }else{
              setIndex(4)
            }
          }else{
            setIndex(3)
          }
        }else{
          setIndex(1)
        }
        
        
       
      }
    })
  }

  function isValidEmail(e) {
    return /\S+@\S+\.\S+/.test(e)
  }
 const verifyFreeTrialExistence = async()=>{
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
  .then(async(res) => {

    if (res.data.length != 0) {
      if (res.data?.status == "completed") {
        setNoneDisp(true)
        setHasFreeTrial(true)
       // setIndex(11)
      //  setWelcomeMsg("Vous avez déjà bénéficié d'une période d'essai. Vous pouvez maintenant souscrire à un abonnement.")
     //   setTexte("")
    //    setColor("error")
        
      }else{
        await getUser()
      }
    }else{
      await getUser()
    }
  })
 } 
 const getFreeTrialPeriod = async()=>{
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/settings`)
  .then(async(res) => {

    dispatch({ type: 'SET_SETTINGS', payload: res.data[0].freeTrialPeriod });
    dispatch({ type: 'SET_MAXTRIALTICKETS', payload: res.data[0].maxTrialTickets});
    dispatch({ type: 'SET_CITIES', payload: res.data[0].cities});
    dispatch({ type: 'SET_NBROFDAYS', payload: res.data[0].freeTrialPeriod});
    dispatch({ type: 'SET_MONTANT', payload: res.data[0].trial.price});
    dispatch({ type: 'SET_SETTINGS_Img', payload: [res.data[0].offers.passImage, res.data[0].offers.subscriptionImage]});
  })
}


//new version 
const updateCredentialsForNullUserId = async(id, p)=>{
  await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {

      if (res.data.credentials.res.email == email) {
        if (res.data.credentials.isNewAccount) {

          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
          {
            credentials:{
              res: {
                userId: id,
                email: email,
                temporaryPassword: p,
                displayName: displayName,
              },
              isNewAccount: res.data.credentials.isNewAccount,
            completedAt: new Date()
            },
            status: "inProgress"
          })
        }else{
          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
          {
            credentials:{
              res: {
                userId: id,
                email: email,
                temporaryPassword: null,
                displayName: displayName,
              },
              isNewAccount: res.data.credentials.isNewAccount,
            completedAt: new Date()
            },
            status: "inProgress"
          })
        }
     
      }
    })

}

const verifyExistenceInFirebase = async(freetrialExist)=>{
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${email}`)
      .catch(async function (error) {
        if (error.response) {
      
        } 
     
      })
      .then(async(res) => {

        if (res.data == "") {
          await createNewUser(password, freetrialExist)
        }else {
          await axios
            .delete(`${process.env.REACT_APP_API_DEV}/athletes/${res.data.userRecord.uid}/delete-from-firebase`)
            .then(
              await createNewUser(password, freetrialExist)
            )
        }

      })
}
const createNewUser = async(p, freetrialExist)=>{
  await axios
  .post(`${process.env.REACT_APP_API_DEV}/athletes/create-athlete`,
  {
      displayName: displayName,
      email: email,
      password: p
  })
  .catch(function (error) {
    if (error.response) {
  //    console.log('response', error.response.data.message)
    //  notifyFailure(error.response.data.message)
      setEmail("")
      setDisplayName("")
      setError(true)
      setDisplaySpinner(false)
      setTexte("Un problème inattendu est survenu, veuillez réessayer à nouveau")
      
    } else if (error.request) {
  //    console.log('request')
    //  notifyFailure("please retry")
    setEmail("")
    setDisplayName("")
    setError(true)
    setDisplaySpinner(false)
    setTexte("Un problème inattendu est survenu, veuillez réessayer à nouveau")
    } else {
  //    console.log('autre')
    //  notifyFailure("please retry")
    setEmail("")
    setDisplayName("")
    setError(true)
    setDisplaySpinner(false)
    setTexte("Un problème inattendu est survenu, veuillez réessayer à nouveau")
    }
 //   console.log(error.config)
  })
  .then(async(res) => {
    const g = res.data
 
    dispatch({ type: 'SET_USER', payload: g });
    if (freetrialExist) {
     await updateCredentialsForNullUserId(g._id, p)
    }else{
      await sendCredentials(g._id, p, true, new Date())
    }
    
    setIndex(1)
  })
}
const verifyUserExist = async(freetrialExist)=>{
  setEmailTransfer(email)
  setDisplayName(displayName)
  await axios
      .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${email}`)
      .then(async(res) => {
        const g = res.data
    
        setUser((prevState) => {
          return g
        })
       // console.log("hna 4")  
      if(g.email != undefined){
     //   console.log("hna 5", g)
          dispatch({ type: 'SET_USER', payload: g });
          if (g?.subscription?.level == 4) {
   
            setNoneDisp(true)
            setHasFreeTrial(true)
          }else if (g?.subscription?.level == 3) {
            setNoneDisp(true)
            setDisplaySpinner(false)
            setWelcomeMsg("Vous avez déjà un abonnement GOLD. Vous pouvez directement réserver des cours de l’application mobile.")
            setTexte("")
            setColor("error")
          }else{

            await getTickets(g._id, "fromVerifyUser", freetrialExist)
            
          }
          
        }else{

          await verifyExistenceInFirebase(freetrialExist)
        }

      })
}
const getFirebaseRecord = async()=>{
  setDisplayNameTransfert(displayName)
    setEmailTransfer(email)
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/userFirebase/${email}`)
        .catch(async function (error) {
          if (error.response) {
         
           await createUser()
          } 

        })
        .then(async(res) => {

            if(res.data.userRecord.phoneNumber){
             
              await updateUserPhone(res.data.userRecord.phoneNumber)
              await updateFreeTrialPhone(res.data.userRecord.phoneNumber)
              await navigatePages()
            }else{
                setIndex(1)
            }

          
        })
}
const verifyUserSubscription = async() =>{
  setEmailTransfer(email)
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${email}`)
  .then(async(res) => {
    const g = res.data
  
    setUser((prevState) => {
      return g
    })
   
    dispatch({ type: 'SET_USER', payload: g }); 
   // console.log("hna 3", g?.subscription?.level)
    if (g?.subscription?.level == 4) {

        setNoneDisp(true)
        setHasFreeTrial(true)
      }else if (g?.subscription?.level == 3) {
        setNoneDisp(true)
        setDisplaySpinner(false)
        setWelcomeMsg("Vous avez déjà un abonnement GOLD. Vous pouvez directement réserver des cours de l’application mobile.")
        setTexte("")
        setColor("error")
      }else{
    
        await getTickets(g._id, "fromSub", false)
       
      }
  })
}
const updateFreeTrialExistanceInfo = async()=>{
  setEmailTransfer(email)
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${email}`)
  .then(async(res) => {
    const g = res.data

    setUser((prevState) => {
      return g
    })
    dispatch({ type: 'SET_USER', payload: g }); 
    if (g?.birthday) {
      await updateBirthdayTrial(g?.birthday)
    }
    if (g?.subscription?.level == 4) {

        setNoneDisp(true)
        setHasFreeTrial(true)
      }else if (g?.subscription?.level == 3) {
        setNoneDisp(true)
        setDisplaySpinner(false)
        setWelcomeMsg("Vous avez déjà un abonnement GOLD. Vous pouvez directement réserver des cours de l’application mobile.")
        setTexte("")
        setColor("error")
      }else{
        if (g.isEmailVerified == false) {
          await updateUserShemaEmail()
        }
        if (g.phoneNumber == undefined) {
          getFirebaseRecord()
        }else{
          
          await updateFreeTrialPhone(g.phoneNumber)
          await navigatePages()
        }
      }
   
    
 
  })
}

const sendSlackReStart = async (trial) =>{

  await axios
  .post(`${process.env.REACT_APP_API_DEV}/freetrial/send-slack-notif`,
  {
    "trialId": trial?._id,
    "email": trial?.credentials?.res?.email,
    "userId": trial?.credentials?.res?.userId

  })
}

const verifyFreeTrialExist = async()=>{
  await axios
  .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
  .then(async(res) => {

    if (res?.data?.length != 0) {
     // console.log("hna 1")

      sendSlackReStart(res.data)
      if (res.data?.status == "completed") {
        setNoneDisp(true)
        setHasFreeTrial(true)   
      }else{
       // console.log("hna 2")
        if (res.data?.phoneNumber?.res) {
          await verifyUserSubscription()
        }
        await verifyUserExist(true)
      }
    }else{
      await verifyUserExist(false)
    }
  })
}
const getTickets = async (id, fromWhichFunc, freetrialExist) =>{

  await axios
    .get(`${process.env.REACT_APP_API_DEV}/tickets/by-user/${id}`)
    .then(async(res) => {
        const tickets = res.data

        if (tickets.length > 0) {
          const tWithInvitedBy = tickets.filter(row => row.invitedBy != null && !row?.status.includes('canceled'));
 
          if (tWithInvitedBy.length > 0) {

            setShowInvitScreen(true)
          }else {
            if (fromWhichFunc.includes("fromSub")) {
              await navigatePages()
            }else{
              if (freetrialExist == false) {
                await sendCredentials(id, null, false, new Date())
              }
              await updateFreeTrialExistanceInfo()
            }
          
          }
        }else {
          if (fromWhichFunc.includes("fromSub")) {
            await navigatePages()
          }else{
            if (freetrialExist == false) {
              await sendCredentials(id, null, false, new Date())
            }
            await updateFreeTrialExistanceInfo()
          }
        
        }
    })
}
const onsubmitt = async () => {

  if (email.length == 0 && displayName.length == 0) {
    setError(true)
    setTexte("Veuillez saisir toutes les informations")
  }else if(email.length == 0 ){
    setError(true)
    setTexte("Veuillez saisir votre adresse mail")
  }else if(displayName.length == 0){
    setError(true)
    setTexte("Veuillez saisir votre nom d'utilisateur")
  }
  else if(isValidEmail(email)){
    setPasswordTransfer(password)

    setDisplaySpinner(true)
    await verifyFreeTrialExist()
   
    // setIndex(1)
  }
  else{
    setError(true)
    setTexte("Veuillez saisir une adresse mail valide!")
  }  
}

  return (
    <PageLayout>
      {hasFretrial && <SalesPage />}
      {!hasFretrial && showInvitScreen &&  <InvitationScreen />}
      {!hasFretrial && !showInvitScreen && <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '100% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
          <Progress color='purple' value={0}/>
          <Help message={"Bonjour, je suis sur le formulaire de la période d'essai et "}/>
          <MDBox textAlign="center" marginTop={0}>
          
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={2}
              >
                 <MDBox textAlign="center"
                 >
                  <p className={`text ${error ? 'error' : ''} ${noneDisp ? 'noneDisp' : ''} `}>{texte}</p>
                </MDBox>
                <MDBox marginTop={2} component='form' role='form'>
                  <MDBox mb={2}>
                    <MDInput
                      className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                      type='email'
                      label='Email'
                      onChange={onEmailChange}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                      type='text'
                      value={displayName}
                      label="Nom d'utilisateur"
                      onChange={onDisplayNameChange}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox textAlign="center" marginTop={2} >
               
                  <p className={`text`} style={{color: "grey", fontSize:"10px"}}>Afin de nous assurer que vous n’êtes pas un robot, veuillez répondre à cette question:</p>
                  <p className={`text`} style={{color: "#222222", fontSize:"10px"}}>quelle est la capitale du Maroc ?</p>
                
                  <MDBox mb={2} marginTop={2}>
                    <MDInput
                      className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                      type='text'
                      label="La capitale du Maroc"
                      onChange={(e)=>onAnswerChange(e)}
                      fullWidth
                    />
                  </MDBox>
                 
                </MDBox>
                
                <MDBox marginTop={2}>
                  <ReCAPTCHA
                  size="normal"
                   sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                   onChange={onChange}
                />
                  </MDBox>
                  <MDBox textAlign="left" marginTop={1}>
                  <p className={`text`} style={{color: "grey", fontSize:"10px"}}>Ce traitement a fait l'objet d'une déclaration sous le n°………. et de 2 demandes de transfert déposées auprès de la CNDP sous le n°……… et le n°………. Vous pouvez vous adresser à   
                  <span style={{color: "#0f2a50", padding: "2px"}} >
                  <a 
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@yofitt.com" 
                    target="_blank" 
                    style={{ textDecoration: 'none !important' }}
                  >
                     contact@yofitt.com 
                  </a> 
                  </span>
                  pour exercer vos droit d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08. </p>

                <div className="container-check"  style={{ display: 'flex'}}>
                <FormControlLabel 
                    style={{ display: 'inline-block' }}
                    control={<Checkbox onChange={e => {setCndpCheck(e.target.checked)}}/>} 
                    />
                    <p className='terms' style={{ display: 'inline-block' }}>
                            <span class="accept">J'ai lu et j'accèpte </span>
                            <span class="link"><a href="https://yofitt.com/conditions-generales-de-vente/" target="_blank">les Conditions Générales de Vente</a></span>
                            </p>
                    </div>
                  </MDBox>
                  <MDBox marginTop={2}>
                  {!disp && !displaySpinner  &&
                     <MDButton
                    // onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='light'
                   >
                     Continuer
                   </MDButton>
                    }

                    {!displaySpinner && disp &&
                    <MDButton
                    className={`ButtonRounded ${noneDisp ? 'noneDisp' : ''} `}
                      onClick={onsubmitt}
                      fullWidth
                      color='purple'
                    >
                      Continuer
                    </MDButton>
                    }
                    {displaySpinner &&
                        <Spinner />
                       }
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign='center'>
               
                   
                  </MDBox>
                  {/**  <MDBox marginTop={3}>
                    <MDButton
                      variant='text'
                      color='white'
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        fontWeight='bold'
                      >
                        <a className='url' href='https://yofitt.com/gold-20/'>
                          Voir nos offres
                        </a>
                      </MDTypography>
                    </MDButton>
                  </MDBox>*/}
                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>}
      
    </PageLayout>
  );
}

export default Basic;
