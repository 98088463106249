/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import logo from 'assets/images/brand.png'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard/bannerDisplay'
import banner from 'assets/images/PROMOGold.png'
import arrow from 'assets/images/arrow2.png'
import ErrorImg from "../../../assets/images/error.png"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic() {
  const [rememberMe, setRememberMe] = useState('');

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const maxTrialTickets = useSelector(state => state.maxtrialtickets);

  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState("Nous n'avons pas pu effectuer votre paiement pour l'instant. Veuillez réessayer!");
  const [texte1, setTexte1] = useState("Veuillez cliquer sur le bouton ci-dessus. Une fois la page chargée, veuillez saisir votre adresse e-mail. Ensuite, vous serez automatiquement redirigé vers la page de confirmation de paiement.")
  const [texte2, setTexte2] = useState("Souscrivez à un abonnement pour continuer à utiliser Yofitt!")
  const [noneDisp, setNoneDisp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);
  const [arrowVisible, setArrowVisible] = useState(true);
  useEffect(async() => {   

  }, []);

  const onsubmit = async () => {
   // window.open('https://free-trial-staging.yofitt.com/free-trial');

  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        {/** 
         *   <nav className='appnavbar'>
      <div className='appnavbar-logo'>
        <img src={logo} alt='applogo' />
      </div>

    </nav>
        */}
        
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <MDBox textAlign="center" marginTop={5}>
          <MDBox  textAlign="center">
              <div className="iconBack">
                <img className="mailIcon" src={ErrorImg} alt="image description" />
              </div>
            </MDBox>
          </MDBox>

          <MDBox textAlign="center" marginTop={2}>
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
          <MDBox textAlign="center" marginTop={2}>
          <p className={`text`}>{texte1}</p>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={3}
              >
                <a href="https://www.essai.yofitt.com/free-trial">
              <MDButton
                     onClick={onsubmit}
                     fullWidth
                   //  sx={{width:190}}
                     color='error'
                   >
                    Obtenir ma période d'essai
                     
                   </MDButton>
                   </a>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;