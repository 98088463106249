/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import SalesPage from "../sales-page"
// firebase 
import moment from 'moment';
import Spinner from "../components/Spinner";
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import { Select } from "antd";

const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

const { Option } = Select;
const currentYear = new Date().getFullYear();
const years = [];
for (let i = 0; i < 10; i++) {
  years.push(currentYear + i);
}

function Basic({setIndex }) {
  const [rememberMe, setRememberMe] = useState('');

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [isHidden, setIsHidden] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [hasFretrial, setHasFreeTrial] = useState(false)
  const [user, setUser] = useState({});
  const [userFirebase, setUserFirebase] = useState(null);
  const [texte, setTexte] = useState('Détail de paiement ' )
  const [errors, setErrors] = useState('');
  const [password, setPassword] = useState('')
  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState('Demande de paiement');
  const [noneDisp, setNoneDisp] = useState(false);
  const [displaySpinner, setDisplaySpinner] = useState(false)
  const [error, setError] = useState(false);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();


  useEffect(async() => {   
  
  }, []);
  const onEmailChange = (e) => {
    setError(false)
    setTexte("Entrez votre adresse mail et nom d'utilisateur")
    setEmail(e.target.value);
    
  };
  const onDisplayNameChange = (e) => {
    setError(false)
    setTexte("Entrez votre adresse mail et nom d'utilisateur")
    setDisplayName(e.target.value);
    
  };



  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
        if (res.data?.city?.res) {
          if(res.data?.startAt?.res){
            if (res.data.activities.res.length != 0) {
              if (res.data?.gender?.res) {
                if (res.data?.selfie?.res){
                  if (res.data?.gym?.completedAt) {
                   
                      setIndex(10)
                      
                    
                    
                  }else{
                    setIndex(8)
                  }
                  
                }else{
                  setIndex(7)
                }
                
              }else{
                setIndex(6)
              }
             }else{
              setIndex(5)
             }
          }else{
            setIndex(4)
          }
        }else{
          setIndex(3)
        }
        
       
      }
    })
  }

  const handleMonthChange = (value) => {
  
  };

  const handleYearChange = (value) => {

  };


const onsubmitt = async () => {

}

  return (
    <PageLayout>
      {hasFretrial && <SalesPage />}
      {!hasFretrial && <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
        <Navbar />
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
          <Progress color='purple' value={0}/>
          <MDBox textAlign="center" marginTop={4}>
          <Typography color={color} variant='h4' justifyContent='center' fontWeight='bold'>
           {welcomeMsg}
            </Typography>
          </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={2}
              >
                 <MDBox textAlign="center"
                 >
                  <p className={`text ${error ? 'error' : ''} ${noneDisp ? 'noneDisp' : ''} `}>{texte}</p>
                </MDBox>
                <MDBox marginTop={2} component='form' role='form'>
                  <MDBox mb={2}>
                    <MDInput
                      className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                      type='text'
                      label='Nom du porteur de la carte'
                //      onChange={onEmailChange}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                      type='text'
                      label="Numéro de carte de paiement"
                     // onChange={onDisplayNameChange}
                      fullWidth
                    />
                  </MDBox >
                  <MDBox mb={2}>
                    <MDInput
                      className={`inputRounded ${noneDisp ? 'noneDisp' : ''} `}
                      type='text'
                      label="Code de vérification"
                     // onChange={onDisplayNameChange}
                      fullWidth
                    />
                  </MDBox >
                  <MDBox mb={2}>
                    <p> Date d'expiration</p>

                  <Select style={{ marginRight: "10px", width:"48%", borderRadius:160 }}  onChange={handleMonthChange} defaultValue="01">
                        <Option value="01">01</Option>
                        <Option value="02">02</Option>
                        <Option value="03">03</Option>
                        <Option value="04">04</Option>
                        <Option value="05">05</Option>
                        <Option value="06">06</Option>
                        <Option value="07">07</Option>
                        <Option value="08">08</Option>
                        <Option value="09">09</Option>
                        <Option value="10">10</Option>
                        <Option value="11">11</Option>
                        <Option value="12">12</Option>
                    </Select>
                    <Select style={{ width:"48%" }}  onChange={handleYearChange} defaultValue={currentYear.toString()}>
                        {years.map((year) => (
                        <Option key={year} value={year.toString()}>
                            {year}
                        </Option>
                        ))}
                    </Select>
               
                  </MDBox>
                  <MDBox marginTop={5}>
                    {!displaySpinner &&
                    <MDButton
                    className={`ButtonRounded ${noneDisp ? 'noneDisp' : ''} `}
                      onClick={onsubmitt}
                      fullWidth
                      color='green'
                    >
                      Valider le paiement
                    </MDButton>
                    }
                    {displaySpinner &&
                        <Spinner />
                       }
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign='center'>
                    <MDTypography variant='button' textTransform="none" color='error'>
                      {errors}
                    </MDTypography>
                  </MDBox>
                  {/**  <MDBox marginTop={3}>
                    <MDButton
                      variant='text'
                      color='white'
                      fullWidth
                    >
                      <MDTypography
                        variant='d8'
                        fontWeight='bold'
                      >
                        <a className='url' href='https://yofitt.com/gold-20/'>
                          Voir nos offres
                        </a>
                      </MDTypography>
                    </MDButton>
                  </MDBox>*/}
                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>}
      
    </PageLayout>
  );
}

export default Basic;
