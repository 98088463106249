/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import WalletImg from "../../../assets/images/Wallet-bro.png"
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import axios from "axios";
// firebase 
import { useSelector } from 'react-redux';
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Modal from "../../../components/MDNotification"
import Image from "assets/images/success.png";
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const user = useSelector(state => state.user);
  const [texte, setTexte] = useState("Nous vous prions de renseigner votre âge réel. L'âge minimum pour s'inscrire est de 16 ans.")
  const [color, setColor] = useState('#000000');
  const [error, setError] = useState(false);
  const [date, setDate] = useState(null)
  const [openNotif, setOpenNotif] = useState(false)
  const [message, setMessage] = useState("")
  const [className, setClassName] = useState("modalContainer")
  const [disableButton, setDisableButton] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [checkAge, setCheckAge] = useState(false)

  const navigatePages = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {
            
        if (res.data?.gender?.res) {
          if (res.data?.gym?.completedAt) {
              if(res.data?.rules?.completedAt){
                if(res.data?.refunded?.completedAt){
                  setSubmitted(true)
                  setIndex(13)
                }else{
                  setIndex(12)
                }
              }else{
                setIndex(11)
              }
            
            
          }else{
            setIndex(9)
          }

      }else{
        setIndex(8)
      }
              
            
           }
      
    })
  }
  const updateUser = async()=>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/athletes/users/${user.email}`)
    .then(async(res) => {
      const g = res.data
      await axios
    .patch(`${process.env.REACT_APP_API_DEV}/athletes/${user._id}`,
    {
      email: user.email,
      birthday: date,
    },).then(async(res)=>{

      setError(false)
     
    })
    })
  }


  const updateFreeTrialBirthDate = async() =>{
    await axios
    .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
    .then(async(res) => {

      if (res.data.length != 0) {

        await axios
        .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
        {
            birthday: {
            res: date,
            completedAt: new Date()
          }
        })
      }
    })
  }
  function onChange(date, dateString) {
    if (date) {
        setError(false)
        setTexte("Nous vous prions de renseigner votre âge réel. L'âge minimum pour s'inscrire est de 16 ans.")
      // If date is not null, user has selected a date
      const selectedDate = date.toDate(); // Convert moment object to JavaScript Date object
      const today = new Date(); // Get today's date
      const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
      const differenceInMonths = today.getMonth() - selectedDate.getMonth();
      const differenceInDays = today.getDate() - selectedDate.getDate();
      // Check if the selected date is at least 16 years ago
      setCheckAge(differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0))
      setDisableButton(true)
  
      setDate(date.format());
    } else {
      // If date is null, user has cleared the date
      setDisableButton(false);
    }
  }
  const onOk = (value) => {
    // console.log('onOk: ', value);
   };
  function disabledDate(current) {
    const currentDate = moment();
    const minimumDate = moment().subtract(82, 'years'); 
    if (current && (current >= currentDate.endOf('day'))) {
      return true;
    }
    if (current && (current.year() < 1940)) {
      return true;
    }

    return current && (current < minimumDate || current > currentDate);
  }


const onsubmit = async() =>{
 await updateUser()
 await updateFreeTrialBirthDate()
 if (checkAge) {
    await navigatePages()
 }else{
    setError(true)
    setTexte("L'âge minimum pour s'inscrire est de 16 ans.")
    setDisableButton(false)
 }
}
const containerRef = useRef(null);
useEffect(async() => {

  
}, [])

  useEffect(() => {   

  }, []);

  return (
    <PageLayout>
    <MDBox
      px={1}
      width='100%'
      bgColor='white'
      height='100vh'
      sx={{
        backgroundImage: ({
          functions: { linearGradient, rgba },
          palette: { gradients },
        }) => image && `url(${image})`,
        backgroundSize: '120% !important',
        backgroundPosition: '45% -250px !important',
        backgroundRepeat: 'no-repeat',
      }}
      mx='auto'
    >
      <Navbar />
      
      <Grid
        container
        spacing={1}
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={-1}>
        <Progress color='purple' value={46.8}/>
        <Help message={`Bonjour, mon mail est ${user?.email}. Je suis sur le formulaire de la période d'essai et  `}/>
        <MDBox textAlign="center">
        <Typography color={color} variant='h4' marginTop={2} justifyContent='center' fontWeight='bold'>
        Entrez votre date de naissance
          </Typography>
        </MDBox>

        <Box>

            <MDBox
              //coloredShadow='dark'
              //pt={4}
              //pb={3}
              //px={3}
                marginTop={2}
              >
              <MDBox textAlign="center"
              >
                <p className={`textbottom ${error ? 'error' : ''} `}>{texte}</p>
              </MDBox>

              <MDBox marginTop={3} component='form' role='form'>
              <MDBox mb={2}>
                     <DatePicker 
                     placeholder="Date de naissance" 
                     className={`datepicker`}
                     style={{ width: '100%', height: 45, borderRadius: 160}}
                     onChange={onChange} 
                     onOk={onOk} 
                     format='MMM Do, YYYY'
                     disabledDate={disabledDate}
                     />
                  </MDBox>
              <MDBox marginTop={2}>
                
                <div className="container-check"  style={{ display: 'flex'}}>
                <FormControlLabel 
                style={{ display: 'inline-block' }}
                control={<Checkbox onChange={e => {setIsChecked(e.target.checked)}}/>} 
                />
                <p className='terms' style={{ display: 'inline-block' }}>
                        <span class="accept">J'approuve que ma date de naissance est correcte. </span>
                        </p>
                </div>
               
              
              </MDBox>
                <MDBox marginTop={3} textAlign='center'>
   
               <MDBox marginTop={2} textAlign='center'>
               {(!isChecked || !disableButton) &&
                 <MDButton
                // onClick={onsubmit}
                 fullWidth
               //  sx={{width:190}}
                 color='light'
               >
                 Continuer
               </MDButton>
                }
               {isChecked && disableButton &&
                 <MDButton
                 onClick={onsubmit}
                 fullWidth
               //  sx={{width:190}}
                 color='purple'
               >
                 Continuer
               </MDButton>
                }
               </MDBox>
              
                
                </MDBox>
             
              </MDBox>
            </MDBox>
          </Box>
        </Grid>
      </Grid>
    </MDBox>
    {openNotif && <Modal setOpenModal={setOpenNotif} img={Image} msg={message} className={className}/>}
  </PageLayout>
    
   

  );
}

export default Basic;