/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Navbar } from '../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Footer from 'layouts/authentication/components/Footer'
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import './styles.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import PageLayout from 'examples/LayoutContainers/PageLayout'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import warningImg from "../../../assets/images/warning.png"
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import 'firebase/compat/auth';
// axios
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// firebase 
import { app } from '../../../firebaseConfig';
import { async } from "@firebase/util";
import Progress from "components/MDProgress"
import Help from "layouts/authentication/components/help"
const image = 'https://yofitt.com/wp-content/uploads/2022/06/BG.jpg?id=2088'

function Basic({setIndex, setSubmitted}) {
  const [rememberMe, setRememberMe] = useState('');

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const maxTrialTickets = useSelector(state => state.maxtrialtickets);

  const [color, setColor] = useState('#000000');
  const [welcomeMsg, setWelcomeMsg] = useState("Warning");
  const [noneDisp, setNoneDisp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const user = useSelector(state => state.user);
  const [error, setError] = useState(false);
  useEffect(async() => {   

  }, []);

    
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  
    const handleSubmit = () => {
      if (isChecked) {
        // Handle submission of the form

      } else {
        // Display error message

      }
    };
    const navigatePages = async() =>{
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
      .then(async(res) => {
  
        if (res.data.length != 0) {
          if (res.data?.birthday?.res) {
            const selectedDate = new Date(res.data?.birthday?.res); // Convert moment object to JavaScript Date object
            const today = new Date();
            const differenceInYears = today.getFullYear() - selectedDate.getFullYear();
            const differenceInMonths = today.getMonth() - selectedDate.getMonth();
            const differenceInDays = today.getDate() - selectedDate.getDate();
            if (differenceInYears > 16 || (differenceInYears === 16 && differenceInMonths >= 0 && differenceInDays >= 0)) {
              if (res.data?.gender?.res) {
                if (res.data?.gym?.completedAt) {
                    if(res.data?.rules?.completedAt){
                      if(res.data?.refunded?.completedAt){
                        setSubmitted(true)
                        setIndex(13)
                      }else{
                        setIndex(12)
                      }
                    }else{
                      setIndex(11)
                    }
                  
                  
                }else{
                  setIndex(9)
                }
  
            }else{
              setIndex(8)
            }
            }else{
              setIndex(7)
            }
          }else{
            setIndex(7)
          }
             }
        
      })
    }
    const updateFreeTrialConfirmPartner = async() =>{
      await axios
      .get(`${process.env.REACT_APP_API_DEV}/freetrial/${user.email}`)
      .then(async(res) => {
 
        if (res.data.length != 0) {
  
          await axios
          .patch(`${process.env.REACT_APP_API_DEV}/freetrial/${res.data._id}`,
          {
            partnerConfirm: {
              completedAt: new Date()
            }
          }).then(async()=>{
           await navigatePages()
          })
          
        }
      })
    }
  const onsubmitt = async () => {
    await updateFreeTrialConfirmPartner()
   // setSubmitted(true)
  }

  return (
    <PageLayout>
      <MDBox
        px={1}
        width='100%'
        bgColor='white'
        height='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) => image && `url(${image})`,
          backgroundSize: '120% !important',
          backgroundPosition: '45% -250px !important',
          backgroundRepeat: 'no-repeat',
        }}
        mx='auto'
      >
       
       
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
 
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} marginTop={6}>
        
          <MDBox textAlign="center" marginTop={-4}>
              <div className="iconWarning">
                <img className="warningIcon" src={warningImg} alt="image description" />
              </div>
            </MDBox>
            <MDBox textAlign="center" >
            <Typography color={"#000000"} variant='h4' textAlign={'center'} justifyContent='center' fontWeight='bold'>
            Seulement un unique partenaire vous intéresse ?
            </Typography>
            </MDBox>
            <Box>
              <MDBox
                //coloredShadow='dark'
                //pt={4}
                //pb={3}
                //px={3}
                marginTop={3}
              >
                <MDBox marginTop={2} component='form' role='form'>
                  
                <div className="scrollable-warning-container">
      <p className="termsText" > Yofitt n'est peut être pas alors la solution optimale pour votre sport.
        </p>
        <p className="termsText" style={{marginTop:15}}>Yofitt est le bon plan lorsque vous êtes intéressé par un minimum de 2 partenaires!</p>       
      
      <p className="termsText">Pensez plutôt à contacter le partenaire en question si vous voulez vraiment vous y inscrire.</p>

      <p className="termsText" style={{marginTop:15}}>Si vous souhaitez tout de même prendre votre période d'essai Yofitt, alors cliquez sur le bouton <span style={{fontWeight:"bold"}}>CONTINUER</span> ci-dessous.</p>

          </div>

                  <MDBox marginTop={3}>
                  <MDButton
                     className={`ButtonRounded ${noneDisp ? 'noneDisp' : ''} `}
                       onClick={onsubmitt}
                       fullWidth
                       color='warning'
                     >
                       Continuer
                     </MDButton>
                    
                  </MDBox>

            
                 
                </MDBox>
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
